import { ExampleModel } from "../components/Example";
import { SyntheticSegmentState } from "../components/SyntheticResponses/SegmentConfiguratorDialog/useSyntheticSegments";

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    FileReadRetrieveRead = "frr",
    ReadDecomposeAsk = "rda",
    PrepareQuestions = "pq"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    index?: string;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
};

export type QuestionExamplesResponse = {
    question: string;
};

export type ChatTurn = {
    file?: string;
    role: "user" | "assistant" | "system";
    content: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export interface ExamplesQuery {
    examples: ExampleModel[];
    isLoading: boolean;
}

export type CitationContent = {
    blob_content: string;
    response_code: number;
    response_headers: {
        "Content-Type": string;
        "Content-Disposition": string;
    };
    error?: string;
};
