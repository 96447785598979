import { useState } from "react";

import styles from "./WelcomeUI.module.scss";

import ImportButtons from "./ImportButtons";
import OneDriveFolderExplorer from "../OneDriveFolderExplorer";
import { useMsAuth } from "./ImportButtons/useMsAuth";
import { QueryKeys, queryClient } from "../../utils/reactQuerySetup";

export default function KnowledgeConnectComponent() {
    const [isFolderExplorerOpen, setIsFolderExplorerOpen] = useState(false);

    const msAuth = useMsAuth();

    const onFolderExplorerToggle = () => {
        setIsFolderExplorerOpen(p => !p);
    };

    const onChangeAccount = async () => {
        try {
            onFolderExplorerToggle();
            await msAuth.logOut();
            await msAuth.logIn();
            queryClient.invalidateQueries({ queryKey: [QueryKeys.oneDriveFoldersContent] });
            onFolderExplorerToggle();
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className={styles.wrapper}>
            <h1>
                Welcome to <span className={styles.blueColor}>Bulbshare AI</span>
            </h1>
            <h2>Connect your knowledge to get started</h2>

            <ImportButtons onFolderExplorerOpen={onFolderExplorerToggle} msAuth={msAuth} />
            <OneDriveFolderExplorer isOpen={isFolderExplorerOpen} onToggleClose={onFolderExplorerToggle} onChangeAccount={onChangeAccount} />
        </div>
    );
}
