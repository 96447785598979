import { SyntheticConversation } from "../../../pages/syntheticResponses/useConversation";
import { Wrapper } from "./styles";
import SegmentSummary from "./SegmentSummary";

interface Props {
    query?: SyntheticConversation.CurrentQuery;
}

const UserQuerySummary = (props: Props) => {
    if (!props.query) {
        return null;
    }

    return (
        <Wrapper>
            <SegmentSummary segment={props.query.segment} />
            <h2>{props.query.question}</h2>
        </Wrapper>
    );
};

export default UserQuerySummary;
