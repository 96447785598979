import React, { useMemo } from "react";
import classNames from "classnames";
import { Skeleton } from "@mui/material";

import styles from "./folderExplorer.module.scss";

import FolderRow from "./FolderRow";
import { getFoldersContentQueryKey, useFoldersContentQuery } from "../../../api/hooks/queries/useFoldersContentQuery";
import Toast from "../../common/Toast";
import { queryClient } from "../../../utils/reactQuerySetup";
import getFoldersContent from "../../../api/foldersContent";

export const ROOT_FOLDER = "root";
export const PDF_MIME_TYPE = "application/pdf";

interface Props {
    folderId: string;
    selectedFolderId?: string;
    onFolderSelect(id?: string): void;
}

const FolderExplorer = (props: Props) => {
    const foldersContentQuery = useFoldersContentQuery({ folderId: props.folderId });

    const data = foldersContentQuery.data?.files;

    const onFolderSelect = async (newSelectedFolderId?: string) => {
        if (newSelectedFolderId === undefined) {
            props.onFolderSelect(undefined);
            return true;
        }

        try {
            props.onFolderSelect(undefined);

            const queryKey = getFoldersContentQueryKey(newSelectedFolderId);

            const data = await queryClient.fetchQuery({
                queryKey,
                staleTime: 1000,
                queryFn: () => getFoldersContent({ folderId: newSelectedFolderId })
            });

            if (data?.files.length) {
                const hasPDFs = data.files.find(f => f.file?.mimeType === PDF_MIME_TYPE);
                if (!hasPDFs) {
                    throw new Error("PDF files are required for a folder to be selected");
                }
            }

            props.onFolderSelect(newSelectedFolderId);

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return (
        <div className={classNames(styles.wrapper, { [styles.isChild]: props.folderId !== ROOT_FOLDER })}>
            {data && data.map(file => <FolderRow key={file.id} folder={file} onFolderSelect={onFolderSelect} selectedFolderId={props.selectedFolderId} />)}
            {!foldersContentQuery.isLoading && (!data || (Array.isArray(data) && !data.length)) && <div className={styles.wrapper}>No folders available</div>}
            {foldersContentQuery.isLoading && <Skeletons />}
            {foldersContentQuery.isError && <Toast type="error" open message={foldersContentQuery.error.message} />}
        </div>
    );
};

const Skeletons = () => {
    const data = useMemo(() => Array.from(new Array(3), (_, i) => ({ width: Math.random() * 35 + 10 })), []);

    return (
        <>
            {data.map((d, i) => (
                <React.Fragment key={i}>
                    <Skeleton variant="circular" width={30} height={30} />
                    <div className={classNames(styles.folder, styles.fullWidth)}>
                        <Skeleton variant="rectangular" width={13} height={11} />
                        <Skeleton variant="rounded" width={`${d.width}%`} height={16} />
                    </div>
                </React.Fragment>
            ))}
        </>
    );
};

export default FolderExplorer;
