import { useState, MouseEvent } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem } from "@mui/material";

import { Button } from "@shared/components/ui/Button";
import { TextField } from "@shared/components/ui";
import { Avatar } from "../UsersTable/UsersItems/DisplayName";
import { SyntheticUser } from "../../../types";

import classNames from "classnames";
import MoreIcon from "../../../assets/more-icon.svg?react";
import styles from "./EditUserDialog.module.scss";

interface Props {
    user: SyntheticUser;
    onConfirm: (updatedUser: SyntheticUser) => void;
}

const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" }
];

const getGender = (gender: string) => {
    const identified = genders.find(g => g.value === gender.toLowerCase());
    return identified ? identified.value : "non-binary";
};

const EditUserDialog = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [user] = useState<SyntheticUser>(props.user);
    const [age, setAge] = useState(Number(props.user.age) || 0);
    const [gender, setGender] = useState(getGender(props.user.gender));
    const [country, setCountry] = useState(props.user.country);
    const [description, setDescription] = useState(props.user.description);

    const open = (e: MouseEvent) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const confirm = async () => {
        setIsOpen(false);

        props.onConfirm({
            ...user,
            age,
            gender,
            description,
            country
        });
    };

    const cancel = () => {
        setAge(Number(props.user.age) || 0);
        setGender(props.user.gender);
        setDescription(props.user.description);
        setCountry(props.user.country);
        close();
    };

    const close = () => {
        setIsOpen(false);
    };

    return (
        <>
            <IconButton onClick={e => open(e)}>
                <MoreIcon />
            </IconButton>
            <Dialog open={isOpen} onClose={close} onClick={e => e.stopPropagation()} fullWidth maxWidth="md">
                <DialogTitle>Edit synthetic persona details</DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div className={classNames(styles.row, styles.grow)}>
                                <Avatar user={props.user} />
                                <TextField value={props.user.displayName} label="Display name" sx={{ pointerEvents: "none" }} />
                            </div>
                            <div className={styles.grow}>
                                <TextField value={country} onChange={e => setCountry(e.target.value)} label="Country" className={styles.grow} />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <TextField value={age} onChange={e => setAge(Number(e.target.value))} label="Age" type="number" />
                            <TextField value={gender} onChange={e => setGender(e.target.value)} label="Gender" select>
                                {genders.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <TextField value={description} onChange={e => setDescription(e.target.value)} label="Description" multiline fullWidth />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" width="112px" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button width="112px" onClick={confirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditUserDialog;
