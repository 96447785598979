import React, { ReactNode } from "react";
import { SxProps, Theme, styled } from "@mui/material";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";

interface Props {
  selected?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onClick: () => void;
  children: ReactNode;
}

export default function SelectCard({ selected, disabled, sx, onClick, children }: Props) {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <Wrapper
      role="button"
      tabIndex={0}
      selected={selected}
      disabled={disabled}
      onKeyUp={handleKeyPress}
      sx={sx}
      onClick={handleClick}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled("div")<{ selected?: boolean; disabled?: boolean }>(
  ({ theme, selected, disabled }) => `
	position: relative;
  border: 1px solid ${selected ? theme.palette.primary.main : colors.base.outline1};
  padding: 1rem 1.5rem;
	box-sizing: border-box;
	user-select: none;
	cursor: pointer;
  
  background-color: ${selected ? colors.grey[100] : colors.base.white};
	border-radius: ${rounding.default};
	text-align: left;
	width: 100%;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	${
    disabled
      ? `
					opacity: 0.5;
					cursor: not-allowed;
    			filter: grayscale(100%);
    			filter: gray;
				`
      : ""
  }

	${theme.breakpoints.down("sm")} {
		padding: 0.75rem;
	}


	${
    !selected && !disabled
      ? `
					&:hover {
						background-color: ${colors.grey[100]};
					}
				`
      : ""
  }
`
);
