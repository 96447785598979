import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "../../../utils/reactQuerySetup";
import postCreateOrganisationIndex from "../../createOrganisationIndex";

interface Args {
    onError?(): void;
}

export const useCreateOrganisationIndex = (args?: Args) =>
    useMutation({ mutationKey: [MutationKeys.folderSelect], mutationFn: postCreateOrganisationIndex, onError: args?.onError });
