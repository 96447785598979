import React from "react";
import { ArrowUpload24Filled } from "@fluentui/react-icons";

import styles from "./style.module.css";

const toBase64 = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const removeMetaFromFile = (file: string) => {
    const endOfMeta = "base64,";
    const index = file.indexOf(endOfMeta);
    return file.slice(index + endOfMeta.length);
};

export const getB64File = async (file: File): Promise<string> => {
    const b64File = await toBase64(file);

    if (typeof b64File === "string") {
        return removeMetaFromFile(b64File);
    } else {
        throw Error(`Expected a string after convertion, got ${typeof b64File}`);
    }
};

interface Props {
    onFileSelected(file: File): void;
    selectedFile?: File;
}
// TODO: deprecated button, remove
const FileUpload = (props: Props) => {
    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) {
            console.error("No files added");
            return;
        }

        const [file] = e.target.files;
        props.onFileSelected(file);
    };

    return (
        <div className={styles.container}>
            <label htmlFor="file-upload" className={styles.fileUploadLabel}>
                <ArrowUpload24Filled />
                File upload
            </label>
            <input id="file-upload" className={styles.fileUpload} type="file" name="file" onChange={onFileChange} />
            {props.selectedFile && <div className={styles.selectedFile}>({props.selectedFile.name})</div>}
        </div>
    );
};

export default FileUpload;
