import { post, put } from "@shared/components/api/http";
import { getHeaders } from "@shared/components/api/utils";
import { VITE_API_BASE_NODE_URL } from "../utils/envVariables";

interface Args {
    accessToken: string;
}

export default async function patchUpdateMSAccessToken(args: Args): Promise<void> {
    return put(
        `${VITE_API_BASE_NODE_URL}/folders/token`,
        { accessToken: args.accessToken },
        {
            headers: getHeaders(),
            credentials: "include"
        }
    );
}
