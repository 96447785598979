const isDefined = (vars: Record<string, string>) => {
    Object.entries(vars).forEach(([key, value]) => {
        if (value === undefined) {
            throw new Error(`Environment variable "${key}" is not defined.`);
        }
    });
};

export const VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE = import.meta.env.VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE;
export const VITE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const VITE_API_BASE_NODE_URL = import.meta.env.VITE_API_BASE_NODE_URL;
export const VITE_MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID;
export const VITE_MICROSOFT_TENANT_ID = import.meta.env.VITE_MICROSOFT_TENANT_ID;
export const VITE_MICROSOFT_CALLBACK_URL = import.meta.env.VITE_MICROSOFT_CALLBACK_URL;
export const VITE_RESOURCES_BASE_URL = import.meta.env.VITE_RESOURCES_BASE_URL;
export const VITE_ONBOARDING_BASE_URL = import.meta.env.VITE_ONBOARDING_BASE_URL;

isDefined({
    VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE,
    VITE_API_BASE_URL,
    VITE_API_BASE_NODE_URL,
    VITE_MICROSOFT_CLIENT_ID,
    VITE_MICROSOFT_TENANT_ID,
    VITE_MICROSOFT_CALLBACK_URL,
    VITE_ONBOARDING_BASE_URL,
    VITE_RESOURCES_BASE_URL
});
