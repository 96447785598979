import { styled } from "@mui/system";

import { Button } from "@shared/components/ui/Button";

const ConnectButton = styled(Button)`
    color: #000;
    border-radius: 10px !important;
    border: 1px solid #d8dae5;
    transition: 0.25s;
    gap: 12px;
    justify-content: flex-start;
    line-height: unset !important;
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;

    &:hover {
        background-color: #eee;
    }

    &:disabled {
        background-color: transparent;
        svg {
            opacity: 0.4;
        }
    }
`;

export default ConnectButton;
