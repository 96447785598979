import { UserElementProps } from "./Generic";
import { styled } from "@mui/system";

const Ellipsis = styled("div")({
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3
});

const DescriptionData = (props: UserElementProps) => {
    if (!props.responseKey) {
        return null;
    }

    return <Ellipsis>{props.user.description}</Ellipsis>;
};

export default DescriptionData;
