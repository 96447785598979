import { post } from "@shared/components/api/http";
import { VITE_API_BASE_URL } from "../utils/envVariables";
import { PersonaResponse } from "../types";

type GeneratePersonaInputProps = {
    age: [number, number];
    gender: string;
    country: string;
    user_ref: string;
    interests?: string[];
};

export default function getGeneratedPersona(props: GeneratePersonaInputProps): Promise<PersonaResponse> {
    return post<GeneratePersonaInputProps, PersonaResponse>(`${VITE_API_BASE_URL}/synthetic_data/generate_persona_for_chat`, props, {
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    });
}
