export const genders = {
    male: "Male",
    female: "Female",
    nonbinary: "Non-binary",
    undisclosed: "Prefer not to say"
};

export const countries = {
    AW: "Aruba",
    AG: "Antigua and Barbuda",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    DZ: "Algeria",
    AZ: "Azerbaijan",
    AL: "Albania",
    AM: "Armenia",
    AD: "Andorra",
    AO: "Angola",
    AS: "American Samoa",
    AR: "Argentina",
    AU: "Australia",
    AT: "Austria",
    AI: "Anguilla",
    AQ: "Antarctica",
    BH: "Bahrain",
    BB: "Barbados",
    BW: "Botswana",
    BM: "Bermuda",
    BE: "Belgium",
    BS: "The Bahamas",
    BD: "Bangladesh",
    BZ: "Belize",
    BA: "Bosnia and Herzegovina",
    BO: "Bolivia",
    BL: "Saint Barthelemy",
    MM: "Myanmar",
    BJ: "Benin",
    BY: "Belarus",
    SB: "Solomon Islands",
    BR: "Brazil",
    BT: "Bhutan",
    BG: "Bulgaria",
    BV: "Bouvet Island",
    BN: "Brunei",
    BI: "Burundi",
    CA: "Canada",
    KH: "Cambodia",
    TD: "Chad",
    LK: "Sri Lanka",
    CG: "Republic of the Congo",
    CD: "Democratic Republic of the Congo",
    CN: "China",
    CL: "Chile",
    KY: "Cayman Islands",
    CC: "Cocos (Keeling) Islands",
    CM: "Cameroon",
    KM: "Comoros",
    CO: "Colombia",
    MP: "Northern Mariana Islands",
    CR: "Costa Rica",
    CF: "Central African Republic",
    CU: "Cuba",
    CV: "Cape Verde",
    CK: "Cook Islands",
    CY: "Cyprus",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    IE: "Ireland",
    GQ: "Equatorial Guinea",
    EE: "Estonia",
    ER: "Eritrea",
    SV: "El Salvador",
    ET: "Ethiopia",
    CZ: "Czech Republic",
    GF: "French Guiana",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands (Islas Malvinas)",
    FM: "Federated States of Micronesia",
    FO: "Faroe Islands",
    PF: "French Polynesia",
    FR: "France",
    TF: "French Southern and Antarctic Lands",
    GM: "The Gambia",
    GA: "Gabon",
    GE: "Georgia",
    GH: "Ghana",
    GI: "Gibraltar",
    GD: "Grenada",
    GG: "Guernsey",
    GL: "Greenland",
    DE: "Germany",
    GP: "Guadeloupe",
    GU: "Guam",
    GR: "Greece",
    GT: "Guatemala",
    GN: "Guinea",
    GY: "Guyana",
    HT: "Haiti",
    HK: "Hong Kong",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HU: "Hungary",
    IS: "Iceland",
    ID: "Indonesia",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IR: "Iran",
    IL: "Israel",
    IT: "Italy",
    CI: "Cote d'Ivoire",
    IQ: "Iraq",
    JP: "Japan",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KP: "North Korea",
    KI: "Kiribati",
    KR: "South Korea",
    CX: "Christmas Island",
    KW: "Kuwait",
    XK: "Kosovo",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LV: "Latvia",
    LT: "Lithuania",
    LR: "Liberia",
    SK: "Slovakia",
    LI: "Liechtenstein",
    LS: "Lesotho",
    LU: "Luxembourg",
    LY: "Libya",
    MG: "Madagascar",
    MQ: "Martinique",
    MO: "Macau",
    MD: "Republic of Moldova",
    YT: "Mayotte",
    MN: "Mongolia",
    MS: "Montserrat",
    MW: "Malawi",
    ME: "Montenegro",
    MK: "North Macedonia",
    ML: "Mali",
    MC: "Monaco",
    MA: "Morocco",
    MU: "Mauritius",
    MR: "Mauritania",
    MT: "Malta",
    OM: "Oman",
    MV: "Maldives",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NC: "New Caledonia",
    NU: "Niue",
    NF: "Norfolk Island",
    NE: "Niger",
    VU: "Vanuatu",
    NG: "Nigeria",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    SR: "Suriname",
    NI: "Nicaragua",
    NZ: "New Zealand",
    PY: "Paraguay",
    PN: "Pitcairn Islands",
    PE: "Peru",
    PK: "Pakistan",
    PL: "Poland",
    PA: "Panama",
    PT: "Portugal",
    PG: "Papua New Guinea",
    PW: "Palau",
    GW: "Guinea-Bissau",
    QA: "Qatar",
    RE: "Reunion",
    RS: "Serbia",
    MH: "Marshall Islands",
    MF: "Saint Martin",
    RO: "Romania",
    PH: "Philippines",
    PR: "Puerto Rico",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    PM: "Saint Pierre and Miquelon",
    KN: "Saint Kitts and Nevis",
    SC: "Seychelles",
    ZA: "South Africa",
    SN: "Senegal",
    SH: "Saint Helena",
    SI: "Slovenia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SG: "Singapore",
    SO: "Somalia",
    ES: "Spain",
    LC: "Saint Lucia",
    SD: "Sudan",
    "SJ-205": "Svalbard",
    "SJ-114": "Jan Mayen",
    SE: "Sweden",
    GS: "South Georgia and the Islands",
    SX: "Sint Maarten",
    SY: "Syrian Arab Republic",
    CH: "Switzerland",
    TT: "Trinidad and Tobago",
    TH: "Thailand",
    TJ: "Tajikistan",
    TC: "Turks and Caicos Islands",
    TK: "Tokelau",
    TO: "Tonga",
    TG: "Togo",
    ST: "Sao Tome and Principe",
    TN: "Tunisia",
    TL: "East Timor",
    TR: "Turkey",
    TV: "Tuvalu",
    TW: "Taiwan",
    TM: "Turkmenistan",
    TZ: "United Republic of Tanzania",
    CW: "Curacao",
    UG: "Uganda",
    GB: "United Kingdom",
    UA: "Ukraine",
    US: "United States",
    BF: "Burkina Faso",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela",
    VG: "British Virgin Islands",
    VN: "Vietnam",
    VI: "Virgin Islands (US)",
    VA: "Holy See (Vatican City)",
    NA: "Namibia",
    PS: "State of Palestine",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    WS: "Samoa",
    SZ: "Swaziland",
    CS: "Serbia and Montenegro",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
};
