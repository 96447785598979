import { SyntheticSegmentState } from "../SegmentConfiguratorDialog/useSyntheticSegments";
import SummaryHeader from "./SummaryHeader";

interface Props {
    segment: SyntheticSegmentState;
}

const SegmentSummary = (props: Props) => {
    const {
        useSelectedOrganisation: [organisation],
        useSelectedChannel: [channel],
        useGender: [gender],
        useCountry: [country],
        useAge: [age]
    } = props.segment;

    return (
        <div className="segmentSummary">
            <SummaryHeader channel={channel} organisation={organisation} />

            <div>Gender: {gender?.label || <SegmentNotSelected />}</div>
            <div>Country: {country?.label || <SegmentNotSelected />}</div>
            <div>Age: {Array.isArray(age) ? `${age[0]} - ${age[1]}` : <SegmentNotSelected />}</div>
        </div>
    );
};

export const SegmentNotSelected = () => <span className="translucent">Not selected</span>;

export default SegmentSummary;
