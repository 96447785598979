import { useEffect, useMemo, useState } from "react";
import { PersonaResponse, OpenAIHistory, SyntheticUser } from "../../../types";
import { chatPersona, initPersona } from "../../../api/syntheticChat";
import { QuestionInput } from "../../QuestionInput";
import { Skeleton } from "@mui/material";
import MessageDialog, { MessageDialogProps } from "../../UsersCSVImport/MessageDialog";

import Loader from "../../Loader";
import styles from "./VirtualChat.module.scss";
import classNames from "classnames";

interface Props {
    virtualPersona: SyntheticUser | undefined;
    onClose?: () => void;
}

const VirtualChat = (props: Props) => {
    const [persona, setPersona] = useState<PersonaResponse>();
    const [error, setError] = useState("");
    const [isSendingMessage, setSendingMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<OpenAIHistory[]>([]);
    const [errorMessage, setErrorMessage] = useState<MessageDialogProps>({ message: null });

    const createPersona = async () => {
        try {
            setError("");

            const generatedPersona = await initPersona({
                user_ref: props.virtualPersona?.userRef || "",
                age: Number(props.virtualPersona?.age),
                country: props.virtualPersona?.country || "n/a",
                gender: props.virtualPersona?.gender || "n/a",
                description: props.virtualPersona?.description
            });

            setPersona(generatedPersona);
            setMessages(generatedPersona.history);
        } catch (e: any) {
            setError(e.response.data.message);
        }
    };

    const chatWithOpenAi = async (message: string) => {
        let chat = null;

        try {
            setSendingMessage(true);

            chat = await chatPersona({ user_ref: persona?.persona_id || "", message, history: messages, generated_persona: persona?.generated_persona });

            if (chat.error) {
                setErrorMessage({
                    title: "Error sending message",
                    message:
                        "The message you tried to send resulted in error. This may happen if sent message violates chat rules or system error. Please contact your administrator to check the reason and try again with different message."
                });
            }

            setMessages(chat.history);
            setSendingMessage(false);
        } catch (e) {}
    };

    useEffect(() => {
        if (props.virtualPersona) {
            createPersona();
        }
    }, [props.virtualPersona]);

    if (!persona && !error) {
        return (
            <div className={styles.container}>
                <div className={classNames(styles.wrapper, styles.center)}>
                    <Loader>Loading persona, please wait...</Loader>
                </div>
            </div>
        );
    }

    if (!!error) {
        return (
            <div className={styles.error}>
                <h1>Error</h1>
                {error}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.chat}>
                    <div className={styles.history}>
                        {messages.map(({ content, role }, index) => (
                            <div key={index} className={`${styles.msg} ${styles[role]}`}>
                                {content}
                            </div>
                        ))}
                        {isSendingMessage && <Skeletons />}
                    </div>
                </div>
            </div>
            <div className={styles.chatInput}>
                <QuestionInput
                    clearOnSend
                    placeholder="What do you want to know? (ie. What are the latest trends about sustainability?)"
                    disabled={isSendingMessage}
                    onSend={question => {
                        setMessages([...messages, { role: "user", content: question }]);
                        chatWithOpenAi(question);
                    }}
                />
            </div>
            <MessageDialog {...errorMessage} onClose={setErrorMessage} />
        </div>
    );
};

export default VirtualChat;

const Skeletons = () => {
    const data = useMemo(() => Array.from(new Array(3), (_, i) => ({ width: Math.random() * 60 + 40 })), []);

    return (
        <div className={`${styles.msg} ${styles.assistant}`}>
            {data.map(({ width }, i) => (
                <Skeleton key={i} variant="text" width={`${width}%`} />
            ))}
        </div>
    );
};
