import ReactDOM from "react-dom/client";
import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import "./index.scss";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import SyntheticResponses from "./pages/syntheticResponses/SyntheticResponses";
import SyntheticResponsesCsvImport from "./pages/syntheticResponsesCsvImport";
import SyntheticResponsesVirtualPersona from "./pages/syntheticResponsesVirtualPersona";
import KnowledgeConnect from "./pages/knowledgeConnect/KnowledgeConnect";
import GettingThingsReady from "./pages/gettingThingsReady/GettingThingsReady";
import { queryClient } from "./utils/reactQuerySetup";
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./utils/muiSetup";
import { Paths } from "./utils/routesEnum";
import { msalConfig } from "./utils/msAuthConfig";

initializeIcons();
const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            {/* TODO:  we might only need to include this on the knowledgeConnect route */}
            <MsalProvider instance={msalInstance}>
                <ThemeProvider theme={muiTheme}>
                    <HashRouter>
                        <Routes>
                            <Route path={Paths.root} element={<Layout />}>
                                <Route path={Paths.knowledgeConnect} element={<KnowledgeConnect />} />
                                <Route path={Paths.gettingThingsReady} element={<GettingThingsReady />} />
                                <Route path={Paths.syntheticResponses} element={<SyntheticResponses />} />
                                <Route path={Paths.syntheticResponsesCsvImport} element={<SyntheticResponsesCsvImport />} />
                                <Route path={Paths.syntheticResponsesVirtualPersona} element={<SyntheticResponsesVirtualPersona />} />
                                <Route index element={<Chat />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </HashRouter>
                </ThemeProvider>
            </MsalProvider>
        </QueryClientProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    //<React.StrictMode>
    <App />
    //</React.StrictMode>
);
