import { useEffect, useState } from "react";
import { MenuItem, Slider } from "@mui/material";

import { Button, TextField } from "@shared/components/ui";
import { SyntheticUser } from "../../../types";

import classNames from "classnames";
import Expander from "../Expander";
import styles from "./VirtualPersona.module.scss";

type Props = {
    persona?: SyntheticUser;
    onCreatePersona: (persona: SyntheticUser) => void;
    reset: () => void;
};

const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" }
];

const VirtualPersona = (props: Props) => {
    const [expanded, setExpanded] = useState(false);
    const [personaRef, setPersonaRef] = useState("");
    const [age, setAge] = useState(18);
    const [gender, setGender] = useState("male");
    const [country, setCountry] = useState("United Kingdom");
    const [description, setDescription] = useState("");

    const reset = () => {
        setExpanded(false);
        setPersonaRef("");
        setAge(18);
        setGender("male");
        setCountry("United Kingdom");
        setDescription("");
    };

    const create = () => {
        const ref = `virtual-persona-${Date.now()}`;
        setPersonaRef(ref);
        props.onCreatePersona({
            age,
            gender,
            country,
            description,
            userRef: ref,
            history: []
        });
    };

    useEffect(() => {
        if (props.persona === undefined) {
            reset();
        }
    }, [props.persona]);

    if (props.persona) {
        const expanderTitle = `${expanded ? "Hide" : "Show"} virtual persona details`;

        return (
            <Expander title={expanderTitle} onChange={expanded => setExpanded(expanded)}>
                <div className={styles.details}>
                    <div className={styles.row}>
                        <div className={styles.data}>
                            <div className={styles.label}>Display name</div>
                            <div className={styles.value}>{personaRef}</div>
                        </div>
                        <div className={styles.data}>
                            <div className={styles.label}>Country</div>
                            <div className={styles.value}>{country}</div>
                        </div>
                        <div className={styles.data}>
                            <div className={styles.label}>Age</div>
                            <div className={styles.value}>{age}</div>
                        </div>
                        <div className={styles.data}>
                            <div className={styles.label}>Gender</div>
                            <div className={styles.value}>{gender}</div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.data}>
                            <div className={styles.label}>Description</div>
                            <pre className={styles.value}>{description}</pre>
                        </div>
                    </div>
                </div>
            </Expander>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.grow}>
                    <TextField value={country} onChange={e => setCountry(e.target.value)} label="Country" className={styles.grow} />
                </div>
            </div>
            <div className={styles.row}>
                <div className={classNames(styles.row, styles.grow)}>
                    <div className={styles.age}>
                        <div className={styles.label}>Age</div>
                        <div className={styles.value}>{age}</div>
                    </div>
                    <div className={styles.slider}>
                        <Slider value={age} onChange={(e, value) => setAge(value as number)} min={1} max={99} />
                    </div>
                </div>
                <div className={classNames(styles.row, styles.grow)}>
                    <TextField value={gender} onChange={e => setGender(e.target.value)} label="Gender" select>
                        {genders.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <TextField
                value={description}
                onChange={e => setDescription(e.target.value)}
                label="Description"
                multiline
                fullWidth
                placeholder="Write persona description..."
            />
            <div className={styles.commands}>
                <Button onClick={props.reset} variant="outlined">
                    Reset
                </Button>
                <Button onClick={create} disabled={!description.length || !country.length}>
                    Create persona
                </Button>
            </div>
        </div>
    );
};

export default VirtualPersona;
