import styles from "./KnowledgeConnect.module.scss";

import KnowledgeConnectComponent from "../../components/KnowledgeConnectComponent";

const KnowledgeConnect = () => {
    return (
        <div className={styles.wrapper}>
            <KnowledgeConnectComponent />
        </div>
    );
};

export default KnowledgeConnect;
