import axios from "axios";
import { VITE_API_BASE_URL } from "../utils/envVariables";
import { PersonaResponse, OpenAIHistory, SyntheticUser, GeneratedPersona } from "../types";

type InitProps = {
    user_ref: string;
    age: number;
    gender: string;
    country: string;
    description?: string;
};

type ChatProps = {
    user_ref: string;
    message: string;
    history: OpenAIHistory[];
    generated_persona?: GeneratedPersona;
};

export const personaListByRef = async (list: string[]) => {
    const url = `${VITE_API_BASE_URL}/synthetic_chat/users/list`;

    const response = await axios.post<any, { data: Required<SyntheticUser[]> }>(
        url,
        { list },
        {
            headers: { "Content-Type": "application/json" },
            withCredentials: true
        }
    );

    return response.data;
};

export const personaByRef = async (ref: string): Promise<Required<SyntheticUser>> => {
    const url = `${VITE_API_BASE_URL}/synthetic_chat/users/${ref}`;

    const response = await axios.get<any, { data: Required<SyntheticUser> }>(url, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};

export const initPersona = async (props: InitProps) => {
    const url = `${VITE_API_BASE_URL}/synthetic_chat/persona/${props.user_ref}`;

    const data = {
        age: props.age,
        gender: props.gender,
        country: props.country,
        ...(props.description ? { description: props.description } : {})
    };

    const response = await axios.post<any, { data: PersonaResponse }>(url, data, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};

export const chatPersona = async (props: ChatProps) => {
    const url = `${VITE_API_BASE_URL}/synthetic_chat/persona/${props.user_ref}/chat`;

    const data = {
        message: props.message,
        history: props.history,
        generated_persona: props.generated_persona
    };

    const response = await axios.post<any, { data: PersonaResponse }>(url, data, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};
