import { useEffect } from "react";
import GettingThingsReadyComponent from "../../components/GettingThingsReadyComponent";
import styles from "./GettingThingsReady.module.scss";
import { QueryKeys, queryClient } from "../../utils/reactQuerySetup";

const GettingThingsReady = () => {
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.user] });
    }, []);
    return (
        <div className={styles.wrapper}>
            <GettingThingsReadyComponent />
        </div>
    );
};

export default GettingThingsReady;
