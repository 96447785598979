import { post } from "@shared/components/api/http";
import { getHeaders } from "@shared/components/api/utils";

interface Args {
    folderId: string;
    indexId: number;
}

export default async function postSelectFolder(args: Args): Promise<void> {
    return post(
        `${import.meta.env.VITE_API_BASE_NODE_URL}/folders/${args.folderId}/select`,
        { indexId: args.indexId },
        {
            headers: getHeaders(),
            credentials: "include"
        }
    );
}
