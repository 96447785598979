import { Autocomplete, TextField } from "@mui/material";

import { StateHook } from "@shared/types";
import { countries, genders } from "./segmentConstants";
import AgeSlider from "./AgeSlider";
import { SegmentWrapper } from "../styles";

export interface GenericAutocomplete {
    label: string;
    value: string;
}

const createAutocompleteObject = ([key, value]: [string, string]) => ({ label: value, value: key });

export const autocompleteGenders = Object.entries(genders).map(createAutocompleteObject);
export const autocompleteCountries = Object.entries(countries).map(createAutocompleteObject);

interface Props {
    useGender: StateHook<GenericAutocomplete | undefined>;
    useCountry: StateHook<GenericAutocomplete | undefined>;
    useAge: StateHook<[number, number]>;
}

const SegmentConfiguratorsWithWrapper = (props: Props) => {
    return (
        <>
            <AgeSlider label="Age:" useAge={props.useAge} />
            <Autocomplete
                options={autocompleteGenders}
                value={props.useGender[0] || null}
                onChange={(e, v) => props.useGender[1](v || undefined)}
                getOptionKey={o => o.value}
                getOptionLabel={o => o.label}
                renderInput={params => <TextField {...params} label="Gender" />}
            />
            <Autocomplete
                options={autocompleteCountries}
                value={props.useCountry[0] || null}
                onChange={(e, v) => props.useCountry[1](v || undefined)}
                getOptionKey={o => o.value}
                getOptionLabel={o => o.label}
                renderInput={params => <TextField {...params} label="Country" />}
            />
        </>
    );
};

export default SegmentConfiguratorsWithWrapper;
