/// <reference types="vite-plugin-svgr/client" />

import csv from "csvtojson";
import { useEffect, useState } from "react";

import styles from "./SyntheticResponsesCsvImport.module.scss";

import classNames from "classnames";
import personas from "./personas.csv?raw";
import Loader from "../../components/Loader";
import UsersImportDialog from "../../components/UsersCSVImport/UsersImportDialog";
import UsersTable from "../../components/UsersCSVImport/UsersTable";
import UserChatDialog from "../../components/UsersCSVImport/UserChatDialog";
import MessageDialog, { MessageDialogProps } from "../../components/UsersCSVImport/MessageDialog";
import { useUserQuery } from "../../api/hooks/queries/useUserQuery";
import { VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE } from "../../utils/envVariables";
import { personaListByRef } from "../../api/syntheticChat";
import { SyntheticUser } from "../../types";

const SyntheticResponsesCsvImport = () => {
    const [loadingPersonas, setLoadingPersonas] = useState(false);
    const [selectedUser, setSelectedUser] = useState<SyntheticUser>();
    const [importedUsers, setImportedUsers] = useState<SyntheticUser[]>([]);
    const [errorMessage, setErrorMessage] = useState<MessageDialogProps>({ message: null });

    const profileQuery = useUserQuery();

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined;

        if (!hasAccess()) {
            timer = setTimeout(() => {
                window.location.replace(`${VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE}/2/?redirect=${window.location.host}`);
            }, 2000);
        }

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [profileQuery.data, profileQuery.error]);

    const loadPersonas = async (data: SyntheticUser[]) => {
        setLoadingPersonas(true);

        const users = await personaListByRef(data.filter(parsedUser => parsedUser.userRef).map(user => user.userRef));
        const merged = data.map(parsedUser => ({
            disabled: false,
            history: [],
            ...(users.find(u => u.userRef === parsedUser.userRef) || { disabled: true }),
            ...parsedUser
        }));

        setImportedUsers(merged);
        setLoadingPersonas(false);
    };

    const onFileSelected = async (f: File) => {
        const content = await f.text();
        const status = await parseCSV(content);
        return status;
    };

    const parseCSV = async (content: string) => {
        let missingHeaders: string[] = [];

        const parsedData = await csv({ ignoreEmpty: true, trim: true })
            .fromString(content)
            .on("header", (headers: string[]) => {
                const requiredHeaders = ["userRef", "displayName", "age", "gender", "country", "tagId", "description"];
                missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
            });

        if (!missingHeaders.length && parsedData.length) {
            loadPersonas(parsedData);
            return true;
        }

        const validationNode = (headers: string[]) => {
            return (
                <>
                    <div>Import encountered CSV file validation error.</div>
                    <div>Following columns are missing from imported file:</div>
                    <ul>
                        {headers.map((header, index) => (
                            <li key={index}>{header}</li>
                        ))}
                    </ul>
                </>
            );
        };

        setErrorMessage({
            title: "Validation error",
            message: validationNode(missingHeaders)
        });

        return false;
    };

    const updateUser = (user: SyntheticUser) => {
        setImportedUsers(importedUsers.map(u => (u.userRef === user.userRef ? user : u)));
    };

    const hasAccess = () => {
        return !profileQuery.isError && profileQuery.data && (profileQuery.data.user_role == 1 || profileQuery.data.user_role == 2);
    };

    useEffect(() => {
        parseCSV(personas);
    }, []);

    if (profileQuery.isLoading) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        );
    }

    return hasAccess() ? (
        <div className={styles.container}>
            <div className={classNames(styles.commandsContainer, styles.outer)}>
                <UsersImportDialog onFileSelected={onFileSelected} />
                <MessageDialog {...errorMessage} onClose={setErrorMessage} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {loadingPersonas && (
                        <div className={styles.loader}>
                            <Loader>Loading personas...</Loader>
                        </div>
                    )}
                    {!!importedUsers.length && !loadingPersonas && (
                        <div className={styles.selectUser}>
                            <h1>Select a persona to chat</h1>
                            <UsersTable users={importedUsers} onSelectedUser={setSelectedUser} onUpdatedUser={updatedUser => updateUser(updatedUser)} />
                        </div>
                    )}
                    {!importedUsers.length && !loadingPersonas && (
                        <div className={styles.chatEmptyState}>
                            <h1 className={styles.chatEmptyStateTitle}>Bulbshare synthetic persona chat</h1>
                        </div>
                    )}
                </div>
            </div>
            <UserChatDialog selectedUser={selectedUser} onClose={() => setSelectedUser(undefined)} onUpdatedUser={user => updateUser(user)} />
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.loginLoadingRoot}>
                <div className={styles.loginLoadingContainer}>
                    {profileQuery.isLoading ? (
                        <>Checking user authentication ...</>
                    ) : profileQuery.isError ? (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>{profileQuery.error.toString()}</p>
                            <p>Redirecting to the login page ...</p>
                        </>
                    ) : (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>Redirecting to the login page ...</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SyntheticResponsesCsvImport;
