import { styled } from "@mui/material";
import { Button } from "@shared/components/ui/Button";

export const BlackWhiteButton = styled(Button)`
    border: 1px solid #63656d;
    color: #63656d;
    background-color: transparent;

    &:hover {
        border: 1px solid #63656d;
        background-color: #6c6c6c10;
    }
`;
