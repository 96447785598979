import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserOrganisation } from "../../api/profile";
import { useCreateOrganisationIndex } from "../../api/hooks/mutations/useCreateOrganisationIndexMutation";
import { useFoldersSelectMutation } from "../../api/hooks/mutations/useFoldersSelectMutation";
import { Paths } from "../../utils/routesEnum";

export const useFolderSelect = () => {
    const useSelectedOrganisation = useState<UserOrganisation>();
    const useSelectedFolderId = useState<string>();

    const navigate = useNavigate();

    const createOrganisationIndexMutation = useCreateOrganisationIndex();
    const folderSelectMutation = useFoldersSelectMutation();

    const isPending = createOrganisationIndexMutation.isPending || folderSelectMutation.isPending;
    const isError = createOrganisationIndexMutation.isError || folderSelectMutation.isError;
    const errors = isError
        ? {
              createOrganisationIndex: createOrganisationIndexMutation.error?.message,
              folderSelectMutation: folderSelectMutation.error?.message
          }
        : undefined;

    const onCreate = async () => {
        try {
            const [selectedOrganisation] = useSelectedOrganisation;
            const [selectedFolderId] = useSelectedFolderId;
            if (!selectedOrganisation) {
                console.error("No organisation selected");
                return;
            }
            if (selectedFolderId === undefined) {
                console.error("No folder selected");
                return;
            }

            const data = await createOrganisationIndexMutation.mutateAsync({
                organisationId: selectedOrganisation.organisationid,
                name: selectedOrganisation.name
            });
            if (createOrganisationIndexMutation.isError) {
                return;
            }
            await folderSelectMutation.mutateAsync({ folderId: selectedFolderId, indexId: parseInt(data.kbindexid, 10) });

            navigate(`/${Paths.gettingThingsReady}`);
        } catch (error) {
            console.error(error);
        }
    };

    const onReset = () => {
        createOrganisationIndexMutation.reset();
        folderSelectMutation.reset();
    };

    return {
        useSelectedOrganisation,
        useSelectedFolderId,
        errors,
        isPending,
        onCreate,
        onReset
    };
};
