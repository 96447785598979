import { CircleImage } from "./CircleImage";
import getResourcesUrl, { IMAGE_TYPES } from "../../../utils/getResourcesUrl";
import { OrganisationChannel } from "../../../api/getOrganisationChannels";
import { UserOrganisation } from "../../../api/profile";

interface Props {
    organisation?: UserOrganisation;
    channel?: OrganisationChannel;
}

const SummaryHeader = (props: Props) => {
    if (!props.organisation) {
        return null;
    }

    return (
        <div className="summaryHeader">
            <h3>{props.organisation?.name}</h3>
            {props.channel && (
                <div className="channelSummary">
                    <OrganisationLogo key={props.channel.logo} uri={props.channel.logo} diameter={48} />
                    <div>{props.channel.name}</div>
                </div>
            )}
        </div>
    );
};

export default SummaryHeader;

interface OrganisationLogoProps {
    uri: string;
    diameter: number;
    className?: string;
}

function OrganisationLogo(props: OrganisationLogoProps) {
    const logoSrc = getResourcesUrl(props.uri, IMAGE_TYPES.CHANNEL_LOGO);

    return <CircleImage className={props.className} diameter={props.diameter} background="rgba(241, 241, 242, 0.9)" src={logoSrc} alt="brand_logo" />;
}
