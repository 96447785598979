import React, { useState } from "react";
import { ClickAwayListener, IconButton, Popper } from "@mui/material";

import MicrosoftLogoIcon from "../../../assets/microsoft-logo.svg?react";
import UploadIcon from "../../../assets/upload-icon.svg?react";

import ClipboardIcon from "../../../assets/clipboard.svg?react";
import styles from "./Clipboard.module.scss";
import ConnectButton from "../../ConnectButton";

import FileUploadModal from "./FileUploadModal";

enum ClipboardButtons {
    microsoft = 1,
    upload
}

const buttonsData: {
    title: string;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    id: ClipboardButtons;
}[] = [
    { title: "Upload file for analysis", Icon: UploadIcon, id: ClipboardButtons.upload }
    // { title: "Replace OneDrive folder", Icon: MicrosoftLogoIcon, id: ClipboardButtons.microsoft }
];

export default function ClipboardMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: MouseEvent | TouchEvent) => {
        e.preventDefault(); // don't trigger handleClick
        setAnchorEl(null);
    };

    const clickHandlers: Record<ClipboardButtons, (e: React.MouseEvent<HTMLButtonElement>) => void> = {
        [ClipboardButtons.microsoft]: () => console.log("microsoft"),
        [ClipboardButtons.upload]: () => {
            setAnchorEl(null);
            setIsFileUploadOpen(true);
        }
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <ClipboardIcon />
            </IconButton>
            <Popper
                anchorEl={anchorEl}
                open={open}
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [-9, 0]
                        }
                    }
                ]}
                sx={{ background: "transparent" }}
                placement="top-start"
            >
                <ClickAwayListener onClickAway={handleClose} mouseEvent="onClick">
                    <div className={styles.menuWrapper}>
                        {buttonsData.map(({ Icon, title, id }, index) => (
                            <ConnectButton key={id} onClick={clickHandlers[id]} style={{ animationDelay: `${0.05 * index}s` }}>
                                <Icon /> {title}
                            </ConnectButton>
                        ))}
                    </div>
                </ClickAwayListener>
            </Popper>
            <FileUploadModal isOpen={isFileUploadOpen} onClose={() => setIsFileUploadOpen(false)} />
        </>
    );
}
