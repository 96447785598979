import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "../../../utils/reactQuerySetup";
import postSelectFolder from "../../foldersSelect";

interface Args {
    onError?(): void;
}

export const useFoldersSelectMutation = (args?: Args) =>
    useMutation({ mutationKey: [MutationKeys.organisationIndexCreate], mutationFn: postSelectFolder, onError: args?.onError });
