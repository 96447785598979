import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { AskResponse, fetchCitationContentApi, CitationContent } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { useEffect, useState } from "react";
import { a } from "@react-spring/web";
import * as fs from "fs";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const [citationContent, setCitationContent] = useState<CitationContent>();
    const[citationError, setCitationError] = useState<Error>();
    const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

    const renderCitationContent = () => {
        if (citationContent) {
            if (citationContent?.response_code === 200) {
                return <iframe title="Citation" src={citationContent.blob_content} width="100%" height={citationHeight} />;
            }
            else {
                if (citationError){
                    return <div>{citationError.message}</div>
                }
        }
    }
        return <div><p className={styles.loadingText}>Loading Document<span className={styles.loadingdots} /></p></div>
    };

    const fetchCitationContent = async () => {
        if (activeCitation) {
            try {
                const result = await fetchCitationContentApi(activeCitation);

                if (result.response_code !== 200) {
                    setCitationError(new Error(result.error));
                    throw new Error(result.error);
                }
                setCitationContent(result);
            } catch (error) {
                console.error(`Failed to fetch citation content: ${error}`);
            }
        }
    };
    useEffect(() => {
        fetchCitationContent();
    }, [activeCitation]);



    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                {renderCitationContent()}
            </PivotItem>
        </Pivot>
    );
};
