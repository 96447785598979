import { Skeleton } from "@mui/material";
import styles from "./Example.module.css";

export const ExampleLoading = () => {
    return (
        <div className={styles.exampleLoading}>
            <Skeleton height={45} style={{ marginBottom: 2 }} />
            <Skeleton height={45} style={{ marginBottom: 2 }} />
            <Skeleton width="60%" height={45} style={{ marginBottom: 2 }} />
        </div>
    );
};
