import { Autocomplete, TextField } from "@mui/material";

import { UserOrganisation } from "../../../../api/profile";
import { StateHook } from "@shared/types";
import { useOrganisationChannelsQuery } from "../../../../api/hooks/queries/useOrganisationChannelsQuery";
import { OrganisationChannel } from "../../../../api/getOrganisationChannels";
import { useUserQuery } from "../../../../api/hooks/queries/useUserQuery";
import { ChannelSelectorWrapper } from "../styles";

interface Props {
    useSelectedOrganisation: StateHook<UserOrganisation | undefined>;
    useSelectedChannel: StateHook<OrganisationChannel | undefined>;
}

const OrganisationsChannelSelector = (props: Props) => {
    const { data: profileData, isLoading: isProfileLoading } = useUserQuery();
    const { data: organisationChannelsData } = useOrganisationChannelsQuery({ organisationId: props.useSelectedOrganisation[0]?.organisationid });

    if (isProfileLoading) {
        return null;
    }

    return (
        <ChannelSelectorWrapper>
            <Autocomplete
                options={profileData?.organisations || []}
                value={props.useSelectedOrganisation[0] || null}
                onChange={(e, v) => props.useSelectedOrganisation[1](v || undefined)}
                getOptionKey={o => o.organisationid}
                getOptionLabel={o => o.name}
                renderInput={params => <TextField {...params} placeholder="Select organisation..." />}
            />
            {organisationChannelsData && organisationChannelsData.length > 0 && (
                <Autocomplete
                    options={organisationChannelsData || []}
                    value={props.useSelectedChannel[0] || null}
                    onChange={(e, v) => props.useSelectedChannel[1](v || undefined)}
                    getOptionKey={o => o.channelId}
                    getOptionLabel={o => o.name}
                    renderInput={params => <TextField {...params} placeholder="Select channel..." />}
                />
            )}
        </ChannelSelectorWrapper>
    );
};

export default OrganisationsChannelSelector;
