import { useState } from "react";
import classNames from "classnames";
import { ButtonBase, Skeleton } from "@mui/material";

import styles from "./folderRow.module.scss";

import { useResetBooleanState } from "@shared/utils/hooks/useResetState";
import FolderOpenIcon from "../../../../assets/folder-open.svg?react";
import FolderClosedIcon from "../../../../assets/folder-closed.svg?react";
import ChevronButton from "../ChevronButton";
import { MicrosoftDriveItem } from "../../../../api/foldersContent";
import FolderExplorer from "..";
import Toast from "../../../common/Toast";

interface Props {
    folder: MicrosoftDriveItem;
    selectedFolderId?: string;
    onFolderSelect(id?: string): Promise<boolean>;
}

const FolderRow = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldShowSelectionError, setShouldShowSelectionError] = useState(false);

    useResetBooleanState([shouldShowSelectionError, setShouldShowSelectionError]);

    const onToggleFolder = () => {
        setIsOpen(p => !p);
    };

    const onFolderSelect = async () => {
        if (props.selectedFolderId === props.folder.id) {
            props.onFolderSelect(undefined);
            return;
        }

        setShouldShowSelectionError(false);
        setIsLoading(true);

        const wasSelected = await props.onFolderSelect(props.folder.id);

        setIsLoading(false);

        if (!wasSelected) {
            setShouldShowSelectionError(true);
        }
    };

    const hasChildren = !!props.folder.folder && props.folder.folder.childCount > 0;
    const isFile = !!props.folder.file;
    return (
        <>
            <ChevronButton chevronProps={{ renderIcon: !isFile && hasChildren, isOpen }} onClick={onToggleFolder} />
            <ButtonBase
                key={props.folder.id}
                className={classNames(styles.folder, {
                    [styles.isSelected]: props.selectedFolderId === props.folder.id,
                    [styles.isLoading]: isLoading,
                    [styles.isError]: shouldShowSelectionError
                })}
                onClick={onFolderSelect}
                disabled={isFile}
            >
                <FolderIconComponent isOpen={isOpen} isFile={isFile} />
                <div>{props.folder.name}</div>

                {isLoading && <Skeleton className={styles.skeleton} variant="rectangular" />}
            </ButtonBase>

            {isOpen && <FolderExplorer folderId={props.folder.id} selectedFolderId={props.selectedFolderId} onFolderSelect={props.onFolderSelect} />}

            <Toast open={shouldShowSelectionError} type="error" message="There are no PDF files in this folder" />
        </>
    );
};

const FolderIconComponent = (props: { isOpen: boolean; isFile: boolean }) => {
    if (props.isFile) {
        return null;
    }

    return props.isOpen ? <FolderOpenIcon /> : <FolderClosedIcon />;
};

export default FolderRow;
