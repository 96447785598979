import { useEffect, useState } from "react";
import { OpenAIHistory, PersonaResponse, SyntheticUser } from "../../../types";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { initPersona } from "../../../api/syntheticChat";

import UserChat from "./UserChat";
import Loader from "../../Loader";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./UserChatDialog.module.scss";

interface Props {
    selectedUser?: SyntheticUser;
    onUpdatedUser: (user: SyntheticUser) => void;
    onClose?: () => void;
}

const UserChatDialog = (props: Props) => {
    const [user, setUser] = useState<SyntheticUser>();
    const [personaResponse, setPersonaResponse] = useState<PersonaResponse>();
    const [error, setError] = useState("");

    const updateHistory = (history: OpenAIHistory[]) => {
        if (user) {
            user.history = history;
            props.onUpdatedUser(user);
        }
    };

    const reset = () => {
        setPersonaResponse(undefined);
        setError("");
    };

    const createPersona = async () => {
        try {
            setError("");

            const response = await initPersona({
                age: Number(props.selectedUser?.age),
                country: props.selectedUser?.country || "n/a",
                gender: props.selectedUser?.gender || "n/a",
                user_ref: props.selectedUser?.userRef || "",
                description: props.selectedUser?.description
            });

            const syntheticUser = {
                ...props.selectedUser,
                ...response.generated_persona,
                userRef: response.persona_id,
                age: Number(response.generated_persona.age),
                history: []
            };

            setPersonaResponse(response);
            setUser(syntheticUser);

            props.onUpdatedUser(syntheticUser);
        } catch (e: any) {
            setError(e.response.data.message);
        }
    };

    useEffect(() => {
        if (props.selectedUser) {
            if (!props.selectedUser.history?.length) {
                createPersona();
            } else {
                setUser(props.selectedUser);
                setPersonaResponse({
                    persona_id: props.selectedUser.userRef,
                    history: props.selectedUser.history,
                    generated_persona: {
                        age: props.selectedUser.age.toString(),
                        gender: props.selectedUser.gender,
                        country: props.selectedUser.country,
                        interests: props.selectedUser.interests || "",
                        survey_responses: props.selectedUser.survey_responses || "",
                        description: props.selectedUser.description || "",
                        tone: props.selectedUser.tone || "",
                        language: props.selectedUser.language || "",
                        speech_patterns: props.selectedUser.speech_patterns || "",
                        emphasis: props.selectedUser.emphasis || ""
                    }
                });
            }
        }
    }, [props.selectedUser]);

    return (
        <Dialog
            open={!!props.selectedUser}
            onClose={props.onClose}
            fullScreen
            fullWidth
            maxWidth="lg"
            TransitionProps={{
                onExited: reset
            }}
        >
            <DialogTitle>{props.selectedUser?.displayName} - synthetic persona</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            {!personaResponse && !error && <Loader>Loading persona, please wait...</Loader>}
            {personaResponse && <UserChat persona={personaResponse} onHistoryUpdate={history => updateHistory(history)} />}
            {!!error && (
                <div className={styles.error}>
                    <h1>Error</h1>
                    {error}
                </div>
            )}
        </Dialog>
    );
};

export default UserChatDialog;
