import { IconButton, IconButtonProps } from "@mui/material";

import styles from "./chevronButton.module.scss";

import ChevronDownIcon from "../../../../assets/chevron-down-solid.svg?react";
import classNames from "classnames";

interface ChevronProps {
    renderIcon: boolean;
    isOpen: boolean;
}

interface Props extends IconButtonProps {
    chevronProps: ChevronProps;
}

const ChevronButton = ({ chevronProps: { renderIcon, isOpen }, ...rest }: Props) => {
    if (!renderIcon) {
        return <div />;
    }
    return (
        <IconButton className={classNames(styles.chevron, { [styles.isOpen]: isOpen })} {...rest}>
            <ChevronDownIcon />
        </IconButton>
    );
};

export default ChevronButton;
