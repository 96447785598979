import React from "react";
import { Box, Button, SxProps, Theme, Typography, styled } from "@mui/material";
import { Step } from "../../../types/steps";
import IconCircleCheckFilled from "../../../assets/svgs/circle-check-filled.svg?react";
import IconCircleCheck from "../../../assets/svgs/circle-check.svg?react";
import colors from "../../../config/theme/colors";
import classNames from "classnames";

interface Props {
  steps: Step[];
  sx?: SxProps<Theme>;
  onClick: (step: Step) => void;
}

export default function Steps({ steps, sx, onClick }: Props) {
  return (
    <Wrapper sx={sx}>
      {steps.map((step) => (
        <StepItem key={step.id} step={step} onClick={onClick} />
      ))}
    </Wrapper>
  );
}

interface StepItemProps {
  step: Step;
  number?: number;
  onClick: (step: Step) => void;
}

function StepItem({ step, number, onClick }: StepItemProps) {
  const Icon = step.completed ? IconCircleCheckFilled : IconCircleCheck;

  return (
    <StepButton
      size="small"
      className={classNames({
        "--active": step.active,
        "--completed": step.completed,
      })}
      disabled={step.disabled}
      onClick={() => onClick(step)}
    >
      <Icon />
      <Typography variant="inherit" fontFamily="Avenir-heavy">
        {number && `${number}. `}
        {step.title}
      </Typography>
    </StepButton>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`;

const StepButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: ${colors.text.tertiaryDark};

  &:hover {
    background: none;
  }

  svg {
    padding-inline: 0.175rem;
  }

  &.--completed {
    svg {
      padding-inline: 0;
      color: ${({ theme }) => theme.palette.primary.main};
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &.--active {
    color: ${colors.base.black};
  }
`;
