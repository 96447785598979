import { useEffect, useMemo, useState } from "react";
import { DialogContent, Skeleton } from "@mui/material";
import { DialogActions } from "@mui/material";
import { chatPersona } from "../../../../api/syntheticChat";
import { QuestionInput } from "../../../QuestionInput";
import { PersonaResponse, OpenAIHistory } from "../../../../types";
import MessageDialog, { MessageDialogProps } from "../../MessageDialog";

import styles from "./UserChat.module.scss";

type Props = {
    persona: PersonaResponse;
    onHistoryUpdate: (history: OpenAIHistory[]) => void;
};

const UserChat = ({ persona, onHistoryUpdate }: Props) => {
    const [isSendingMessage, setSendingMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<OpenAIHistory[]>([]);
    const [errorMessage, setErrorMessage] = useState<MessageDialogProps>({ message: null });

    useEffect(() => {
        if (persona) {
            setMessages(persona.history);
        }
    }, []);

    const chatWithOpenAi = async (message: string) => {
        let chat = null;

        try {
            setSendingMessage(true);

            chat = await chatPersona({ user_ref: persona.persona_id, message, history: messages, generated_persona: persona.generated_persona });

            if (chat.error) {
                setErrorMessage({
                    title: "Error sending message",
                    message:
                        "The message you tried to send resulted in error. This may happen if sent message violates chat rules or system error. Please contact your administrator to check the reason and try again with different message."
                });
            }

            setMessages(chat.history);
            setSendingMessage(false);
            onHistoryUpdate(chat.history);
        } catch (e) {}
    };

    return (
        <>
            <DialogContent className={styles.wrapper}>
                <div className={styles.chat}>
                    {messages.map(({ content, role }, index) => (
                        <div key={index} className={`${styles.msg} ${styles[role]}`}>
                            {content}
                        </div>
                    ))}
                    {isSendingMessage && <Skeletons />}
                </div>
            </DialogContent>
            <DialogActions>
                <div className={styles.chatInput}>
                    <QuestionInput
                        clearOnSend
                        placeholder="What do you want to know? (ie. What are the latest trends about sustainability?)"
                        disabled={isSendingMessage}
                        onSend={question => {
                            setMessages([...messages, { role: "user", content: question }]);
                            chatWithOpenAi(question);
                        }}
                    />
                </div>
            </DialogActions>
            <MessageDialog {...errorMessage} onClose={setErrorMessage} />
        </>
    );
};

export default UserChat;

const Skeletons = () => {
    const data = useMemo(() => Array.from(new Array(3), (_, i) => ({ width: Math.random() * 60 + 40 })), []);

    return (
        <div className={`${styles.msg} ${styles.assistant}`}>
            {data.map(({ width }, i) => (
                <Skeleton key={i} variant="text" width={`${width}%`} />
            ))}
        </div>
    );
};
