import { createTheme } from "@mui/material";

const BORDER_RADIUS_VALUE = 10;
const borderRadius = `${BORDER_RADIUS_VALUE}px`;

export const muiTheme = createTheme({
    typography: {
        fontFamily: [
            "Avenir",
            "Segoe UI",
            "-apple-system",
            "BlinkMacSystemFont",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif"
        ].join(",")
    },
    palette: {
        primary: {
            main: "#126ae8"
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius,
                    transition: "all 0.2s ease-in-out"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "20px",
                    marginBottom: "24px",
                    borderBottom: "1px solid #dfdfdf"
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "24px 32px",
                    marginTop: "12px",
                    borderTop: "1px solid #dfdfdf"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    boxShadow: "none"
                }
            }
        }
    }
});
