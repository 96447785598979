import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useUserQuery } from "../../api/hooks/queries/useUserQuery";
import Loader from "../../components/Loader";
import classNames from "classnames";
import { useIsScrollOnTop } from "./useIsScrollOnTop";

const Layout = () => {
    const userQuery = useUserQuery();

    const isScrollOnTop = useIsScrollOnTop();

    if (userQuery.isLoading) {
        return <Loader />;
    }

    return (
        <div className={styles.layout}>
            <header className={classNames(styles.header, { [styles.transparent]: !isScrollOnTop })} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img
                            src="https://admin.bulbshare.com/assets/dashboard/images/bulbshare/bulbshare-logo-white.svg"
                            aria-hidden="true"
                            aria-label="Bulbshare"
                        />
                    </Link>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
