import { useState } from "react";
import { SyntheticSegmentState } from "../../components/SyntheticResponses/SegmentConfiguratorDialog/useSyntheticSegments";
import { getUserResponse, getUserList, getResponsesSummary, getConversation } from "../../api/getSyntheticUserResponse";

export declare module SyntheticConversation {
    export interface User {
        age: number;
        country: string;
        displayName: string;
        gender: string;
        userId: number;
        userRef: string;
        avatar?: string;
    }

    export interface UserResponse {
        query?: string;
        response?: string;
        userRef: string;
    }

    export type FullUserWithResponse = User & { response: string };

    export interface ResponseSummary {
        summary: string;
    }

    export interface ResponseConversation {
        response: string;
    }

    export interface SelectedResponse {
        user: SyntheticConversation.User;
        response: SyntheticConversation.UserResponse;
    }

    export interface CurrentQuery {
        question: string;
        segment: SyntheticSegmentState;
    }

    export interface Conversation {
        question: string;
        answer?: string;
    }
}

export const useConversation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentQuery, setCurrentQuery] = useState<SyntheticConversation.CurrentQuery>();
    const [users, setUsers] = useState<SyntheticConversation.User[] | undefined>();
    const [userResponses, setUserResponses] = useState<Map<string, SyntheticConversation.UserResponse>>(new Map());
    const [summary, setSummary] = useState<string>();
    const [conversation, setConversation] = useState<SyntheticConversation.Conversation[]>([]);

    const getFullUserResponses = (users: SyntheticConversation.User[], userResponses: Map<string, SyntheticConversation.UserResponse>) => {
        return users
            ?.map(user => {
                const userResponse = userResponses.get(user.userRef);
                if (userResponse?.response) {
                    return { ...user, response: userResponse.response };
                }
            })
            .filter(Boolean) as SyntheticConversation.FullUserWithResponse[];
    };

    const fetchUsers = async (segment: SyntheticSegmentState) => {
        try {
            const result = await getUserList(segment);
            setUsers(result);

            return result;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUserResponse = async (opts: { segment: SyntheticSegmentState; user: SyntheticConversation.User; question: string }) => {
        const { userRef } = opts.user;
        try {
            setUserResponses(p => {
                return p.set(userRef, { userRef });
            });
            const result = await getUserResponse(opts);

            setUserResponses(p => {
                return new Map(p.set(userRef, { userRef, response: result.response }));
            });

            return result;
        } catch (error) {
            console.error(error);
        } finally {
            setUserResponses(prev => {
                const current = prev.get(userRef);
                if (current) {
                    return prev;
                }
                prev.delete(userRef);
                return new Map(prev);
            });
        }
    };

    const fetchSummary = async (args: { users: SyntheticConversation.User[]; userResponses: SyntheticConversation.UserResponse[]; question: string }) => {
        if (!args.users.length) {
            setSummary("No users' responses to summarise.");
        }
        try {
            getFullUserResponses;
            const userResponses = args.users
                .map(user => {
                    const userResponse = args.userResponses.find(r => r.userRef === user.userRef);
                    if (userResponse?.response) {
                        return { ...user, response: userResponse.response };
                    }
                })
                .filter(Boolean) as SyntheticConversation.FullUserWithResponse[];

            const result = await getResponsesSummary({ question: args.question, userResponses });
            setSummary(result.summary);
        } catch (error) {
            console.error(error);
        }
    };

    const resetState = () => {
        setIsLoading(false);
        setCurrentQuery(undefined);
        setUsers(undefined);
        setUserResponses(new Map());
        setSummary(undefined);
        setConversation([]);
    };

    const startConversation = async (segment: SyntheticSegmentState, question: string) => {
        resetState();
        try {
            setIsLoading(true);
            setCurrentQuery({ segment, question });
            const currentUsers = await fetchUsers(segment);
            if (!currentUsers || !currentUsers.length) {
                throw new Error("No users received");
            }

            const currentUserResponses: SyntheticConversation.UserResponse[] = [];
            await currentUsers.reduce(async (acc: Promise<void>, user) => {
                await acc;

                const userResponse = await fetchUserResponse({ segment, user, question });
                if (userResponse) {
                    currentUserResponses.push(userResponse);
                }
                return acc;
            }, Promise.resolve());

            await fetchSummary({ question, userResponses: currentUserResponses, users: currentUsers });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const continueConversation = async (question: string) => {
        if (!currentQuery?.question) {
            console.warn("No current question.");
            return;
        }
        if (!users) {
            console.warn("No users.");
            return;
        }
        if (!userResponses) {
            console.warn("No user responses.");
            return;
        }
        try {
            setConversation(p => [...p, { question }]);
            const existingUserResponses = getFullUserResponses(users, userResponses);

            const result = await getConversation({ userResponses: existingUserResponses, question: currentQuery.question, newQuestion: question });

            setConversation(p => [...p.slice(0, p.length - 1), { question, answer: result.response }]);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        isLoading,
        currentQuery,
        users,
        userResponses,
        summary,
        showAnswer: Array.isArray(users) || isLoading,
        conversation,
        startConversation,
        continueConversation
    };
};
