import { styled } from "@mui/system";
import { CircularProgress, Skeleton } from "@mui/material";

import { PaddedPaper } from "../styled";
import { useMemo } from "react";

interface Props {
    text?: string;
    placeholderRows?: number;
    shouldHideCircularLoader?: boolean;
}
export const LoaderWrapper = styled(PaddedPaper)`
    .loader {
        margin: 32px 0px 12px;
        display: flex;
        justify-content: center;
    }
`;

const PaperLoader = (props: Props) => {
    const placeholderRows = useMemo(() => {
        if (typeof props.placeholderRows === "number") {
            return Array.from(new Array(props.placeholderRows), (_, i) => ({ key: i, width: 70 + Math.random() * 30 }));
        }
    }, [props.placeholderRows]);
    return (
        <LoaderWrapper>
            <div>{props.text}</div>
            {placeholderRows?.map(row => (
                <Skeleton key={row.key} variant="text" width={`${row.width}%`} />
            ))}
            {!props.shouldHideCircularLoader && (
                <div className="loader">
                    <CircularProgress size={48} />
                </div>
            )}
        </LoaderWrapper>
    );
};

export default PaperLoader;
