import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../utils/reactQuerySetup";
import getOrganisationChannels from "../../getOrganisationChannels";

interface Args {
    organisationId?: number;
}

export const useOrganisationChannelsQuery = (args: Args) => {
    return useQuery({
        queryKey: [QueryKeys.organisationChannels, args],
        queryFn: () => (args.organisationId !== undefined ? getOrganisationChannels({ organisationId: args.organisationId }) : undefined),
        retry: false,
        enabled: !!args.organisationId
    });
};
