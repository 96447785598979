import { useState } from "react";
import { SyntheticConversation } from "../../../../pages/syntheticResponses/useConversation";
import getResourcesUrl, { IMAGE_TYPES } from "../../../../utils/getResourcesUrl";
import { CircleImage } from "../../UserQuerySummary/CircleImage";
import { UserResponseElementProps } from "./Generic";

import styles from "./UserResponseItems.module.scss";

const DisplayNameResponse = (props: UserResponseElementProps) => {
    return (
        <div className={styles.displayName}>
            <div className={styles.name}>{props.user.displayName}</div>
            <Avatar user={props.user} />
        </div>
    );
};

const Avatar = (props: { user: SyntheticConversation.User }) => {
    const [isError, setIsError] = useState(false);

    if (!props.user.avatar || isError) {
        return null;
    }
    const logoSrc = getResourcesUrl(props.user.avatar, IMAGE_TYPES.USER_AVATAR);

    return <CircleImage diameter={62} src={logoSrc} alt={`${props.user.displayName}'s avatar`} onError={() => setIsError(true)} />;
};

export default DisplayNameResponse;
