import React, { PropsWithChildren } from "react";

import styles from "./styles.module.css";
import { CircularProgress } from "@mui/material";

const Loader = (props: PropsWithChildren) => {
    return (
        <div className={styles.logo}>
            <CircularProgress size={48} />
            {props.children}
        </div>
    );
};

export default Loader;
