import { useEffect, useState } from "react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import styles from "./UserResponseItems.module.scss";
import { ClickableTableRow } from "./styled";

import GenericUserResponse, { UserResponseElementProps } from "./UserResponseItems/Generic";
import UserRefUserResponse from "./UserResponseItems/UserRef";
import UserImpersonatorDialog from "../SyntheticPersonaDialog";
import { SyntheticConversation } from "../../../pages/syntheticResponses/useConversation";
import PaperLoader from "../PaperLoader";
import fetchCountries from "../../../api/fetchCountries";
import { Country } from "../../../types";
import { SelectOption } from "../../../../@shared/src";
import DisplayNameResponse from "./UserResponseItems/DisplayNameResponse";

enum Columns {
    userName = "userName",
    age = "age",
    gender = "gender",
    country = "country",
    response = "response"
}

interface ColumnItem {
    id: Columns;
    name: string;
    userKey?: keyof SyntheticConversation.User;
}

const headerItems: ColumnItem[] = [
    { id: Columns.userName, name: "Display name", userKey: "displayName" },
    { id: Columns.age, name: "Age", userKey: "age" },
    { id: Columns.gender, name: "Gender", userKey: "gender" },
    { id: Columns.country, name: "Country", userKey: "country" },
    { id: Columns.response, name: "Response" }
];

const contentItems: Record<Columns, React.ComponentType<UserResponseElementProps>> = {
    [Columns.userName]: DisplayNameResponse,
    [Columns.age]: GenericUserResponse,
    [Columns.gender]: GenericUserResponse,
    [Columns.country]: GenericUserResponse,
    [Columns.response]: UserRefUserResponse
};

interface Props {
    users?: SyntheticConversation.User[];
    responses: Map<string, SyntheticConversation.UserResponse>;
}

const UserResponsesTable = (props: Props) => {
    // const [countries, setCountries] = useState<SelectOption[]>();

    // TODO: we want to use this everywhere in the app!
    // useEffect(() => {
    //     (async function fetchCountryData() {
    //         const dataCountries = await fetchCountries();
    //         setCountries(dataCountries.map(({ countryId, name }) => ({ value: countryId + " ", label: name })));
    //     })();
    // }, []);

    const useSelectedResponse = useState<SyntheticConversation.SelectedResponse>();

    const onResponseSelect = (user: SyntheticConversation.User, response: SyntheticConversation.UserResponse) => {
        useSelectedResponse[1]({ user, response });
    };

    if (!props.users) {
        return <PaperLoader text="We're searching for the appropriate users, please hold on..." />;
    }

    return (
        <>
            {props.users.length ? (
                <TableContainer component={Paper} className={styles.wrapper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {headerItems.map((item, index) => (
                                    <TableCell key={item.id}>{item.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.users.map((user, index) => {
                                const userResponse = props.responses.get(user.userRef);
                                const hasLoaded = !!userResponse?.response;

                                return (
                                    <ClickableTableRow
                                        key={hasLoaded ? userResponse.userRef : `loading-${index}`}
                                        onClick={() => userResponse && onResponseSelect(user, userResponse)}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        hover={hasLoaded}
                                        selected={hasLoaded && userResponse?.userRef === useSelectedResponse[0]?.user.userRef}
                                    >
                                        {headerItems.map(headerItem => {
                                            const ContentComponent = contentItems[headerItem.id];
                                            return (
                                                <TableCell key={headerItem.id} sx={{ verticalAlign: "baseline" }}>
                                                    {hasLoaded ? (
                                                        <ContentComponent
                                                            key={headerItem.id}
                                                            response={userResponse}
                                                            user={user}
                                                            responseKey={headerItem.userKey}
                                                        />
                                                    ) : (
                                                        <Skeleton />
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </ClickableTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Paper sx={{ padding: "16px" }}>No users in this channel found</Paper>
            )}
            <UserImpersonatorDialog useSelectedResponse={useSelectedResponse} />
        </>
    );
};

export default UserResponsesTable;
