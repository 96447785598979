import { useState } from "react";
import getResourcesUrl, { IMAGE_TYPES } from "../../../../utils/getResourcesUrl";
import { UserElementProps } from "./Generic";

import styles from "../UsersTable.module.scss";
import { CircleImage } from "../../../SyntheticResponses/UserQuerySummary/CircleImage";
import { SyntheticUser } from "../../../../types";

const DisplayNameData = (props: UserElementProps) => {
    return (
        <div className={styles.displayName}>
            <div className={styles.name}>{props.user.displayName}</div>
            <Avatar user={props.user} />
        </div>
    );
};

export const Avatar = (props: { user: SyntheticUser }) => {
    const [isError, setIsError] = useState(false);

    if (!props.user.avatar || isError) {
        return null;
    }
    const logoSrc = getResourcesUrl(props.user.avatar, IMAGE_TYPES.USER_AVATAR);

    return <CircleImage diameter={62} src={logoSrc} alt={`${props.user.displayName}'s avatar`} onError={() => setIsError(true)} />;
};

export default DisplayNameData;
