import { post } from "@shared/components/api/http";
import { VITE_API_BASE_NODE_URL } from "../utils/envVariables";

interface OrganisationIndex {
    organisation: number;
    name: string;
    value: string;
    updateUser: number;
    insertDate: string;
    insertUser: number;
    removed: boolean | null;
    updateDate: string | null;
    kbindexid: string;
}

interface Args {
    organisationId: number;
    name: string;
}

export default async function postCreateOrganisationIndex(args: Args) {
    return post<Args, OrganisationIndex>(`${VITE_API_BASE_NODE_URL}/organisation-indexes/create`, args, {
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    });
}
