import { useState } from "react";

import TextField from "@mui/material/TextField";
import { IconButton, Skeleton } from "@mui/material";

import SendArrowIcon from "../../assets/send-arrow.svg?react";

import styles from "./QuestionInput.module.scss";
import ClipboardMenu from "./ClipboardMenu";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    shouldRenderSkeleton?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, shouldRenderSkeleton }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: newValue } = e.currentTarget;
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <div className={styles.wrapper}>
            <ClipboardMenu />
            {shouldRenderSkeleton ? (
                <Skeleton variant="text" sx={{ width: "100%" }} />
            ) : (
                <TextField
                    InputProps={{ disableUnderline: true, className: styles.chatInput }}
                    className={styles.chatInterface}
                    multiline
                    fullWidth
                    variant="standard"
                    placeholder={placeholder}
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                />
            )}

            <IconButton className={styles.sendButton} onClick={sendQuestion} aria-label="Ask question button" disabled={sendQuestionDisabled}>
                <SendArrowIcon />
            </IconButton>
        </div>
    );
};
