// taken from reports, move to shared

import React from "react";
import { styled } from "@mui/material";
import Slider from "@mui/material/Slider";
import { StateHook } from "../../../../../shared/src/types";

const Wrapper = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    flex: 1;

    .ageRange {
        text-align: center;

        .label {
            margin-right: 8px;
        }
    }
`;

type Props = {
    label?: string;
    useAge: StateHook<number[]>;
};
export default function AgeSlider(props: Props) {
    const [value] = props.useAge;
    const ageMin = value[0];
    const ageMax = value[1];
    return (
        <Wrapper>
            <div className="ageRange">
                {props.label !== undefined && <span className="label">{props.label}</span>}
                {ageMin}
                {" - "}
                {ageMax === 90 ? `${ageMax}+` : ageMax}
            </div>
            <Slider
                valueLabelDisplay="off"
                onChange={(event: Event, newValue: number | number[]) => {
                    if (Array.isArray(newValue)) {
                        props.useAge[1](newValue);
                    }
                }}
                min={16}
                max={90}
                value={props.useAge[0]}
            />
        </Wrapper>
    );
}
