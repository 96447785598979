import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { Button } from "@shared/components/ui/Button";
import { Dropzone } from "@shared/components/ui";

import CloseIcon from "@shared/assets/svgs/close-dialog.svg?react";
import styles from "./UsersImportDialog.module.scss";

interface Props {
    onFileSelected(file: File): boolean | Promise<boolean>;
}

const UsersImportDialog = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState<File>();

    const onClose = () => {
        setIsOpen(false);
    };

    const onFileChange = (files: File[]) => {
        if (files.length) {
            setFile(files[0]);
        }
    };

    const onFileClear = (e: React.MouseEvent) => {
        e.stopPropagation();
        setFile(undefined);
    };

    const onConfirm = async () => {
        if (file) {
            const success = await props.onFileSelected(file);

            if (success) {
                onClose();
            } else {
                setFile(undefined);
            }
        }
    };

    return (
        <>
            <Button variant="outlined" width="auto" onClick={() => setIsOpen(true)}>
                Import CSV
            </Button>
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md" onTransitionExited={() => setFile(undefined)}>
                <DialogTitle>Import users from CSV file</DialogTitle>
                <DialogContent className={styles.content} sx={{ paddingBottom: "12px" }}>
                    <Dropzone onChange={onFileChange} mediaType="csvText" wrapperClassName="dropzoneWrapper">
                        {file ? <p>{file.name} selected</p> : <p>Click here or drag & drop a CSV file</p>}
                    </Dropzone>
                    {file && (
                        <IconButton className={styles.close} sx={{ position: "absolute" }} onClick={onFileClear}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" width="112px" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button width="112px" onClick={onConfirm} disabled={!file}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UsersImportDialog;
