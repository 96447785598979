import { ReactNode, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Button } from "@shared/components/ui/Button";

export interface MessageDialogProps {
    title?: string;
    message: ReactNode | null;
    onClose?: (props: MessageDialogProps) => void;
}

const MessageDialog = (props: MessageDialogProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const close = () => {
        if (props.onClose) {
            props.onClose({ message: null });
        }
    };

    useEffect(() => {
        setIsOpen(props.message !== null);
    }, [props.message]);

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>{props.message}</DialogContent>
            <DialogActions>
                <Button width="112px" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
