import { SyntheticConversation } from "../../../../pages/syntheticResponses/useConversation";

export interface UserResponseElementProps {
    response: SyntheticConversation.UserResponse;
    user: SyntheticConversation.User;
    responseKey?: keyof SyntheticConversation.User;
}

const GenericUserResponse = (props: UserResponseElementProps) => {
    if (!props.responseKey) {
        return null;
    }

    return <>{props.user[props.responseKey]}</>;
};

export default GenericUserResponse;
