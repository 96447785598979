import { styled } from "@mui/system";

type CircleImageProps = {
    diameter: number;
    background?: string;
    border?: string;
};
export const CircleImage = styled("img")<CircleImageProps>(
    ({ diameter = 2, background = "white", border = "none" }) => `
    width: ${diameter}px;
    height: ${diameter}px;
    border: ${border};
    border-radius: 50%;
    position: relative;
    background: ${background};
    object-fit: cover;
`
);
