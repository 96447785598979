import { useEffect } from "react";

type StateHook<T> = [T, React.Dispatch<React.SetStateAction<T>>];

// resets state after duration to a defaultState value
const useResetState = <T>(hook: StateHook<T>, args: { duration: number; defaultState: T; conditionCheck: (v: T) => boolean }) => {
    useEffect(() => {
        // stop showing error after a moment
        const timer = args.conditionCheck(hook[0])
            ? setTimeout(() => {
                  hook[1](args.defaultState);
              }, args.duration)
            : undefined;

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [hook[0]]);
};

const isTrueDefaultCheck = <T>(b: T) => !!b;

/* resets a "boolean" state to false after duration
 * ***
 * usage:
 * const useBooleanState = useState(false)
 * useResetBooleanState(useBooleanState, 250)
 * ***
 */
export const useResetBooleanState = (hook: StateHook<boolean>, duration = 2000) =>
    useResetState(hook, { duration, defaultState: false, conditionCheck: isTrueDefaultCheck });
