import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../utils/reactQuerySetup";
import { getPromptsApi } from "../../api";

interface Args {
    indexName: string;
}

export const usePromptsQuery = (args: Args) => {
    return useQuery({ queryKey: [QueryKeys.prompts, args], queryFn: () => getPromptsApi(args.indexName) });
};
