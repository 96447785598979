/// <reference types="vite-plugin-svgr/client" />

import { useRef, useState, useEffect, useMemo } from "react";
import { Navigate } from "react-router";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";

import styles from "./Chat.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn, getPromptsApi, ExamplesQuery } from "../../api";
import Loader from "../../components/Loader";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList, ExampleModel, ExampleLoadingList, RefreshExamplesButton, EXAMPLES } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import FileUpload, { getB64File } from "../../components/FileUpload";
import { useUserQuery } from "../../api/hooks/queries/useUserQuery";
import { UploadedFileContext } from "../../context/uploadedFileContext";
import { Paths } from "../../utils/routesEnum";
import { VITE_ONBOARDING_BASE_URL } from "../../utils/envVariables";
import { hasIndexes, useGetIndexList } from "../../utils/indicesHelpers";
import { usePromptsQuery } from "../../api/hooks/queries/usePromptsQuery";

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 292 } };

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [indexName, setIndexName] = useState<string>("gptkbindex");
    const [retrieveCount, setRetrieveCount] = useState<number>(10);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [selectedFile, setSelectedFile] = useState<File>();

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, file?: string][]>([]);

    const profileQuery = useUserQuery();
    const promptsQuery = usePromptsQuery({ indexName });

    const indexList = useGetIndexList({ onIndexListChange: setIndexName });

    const getIsFileInHistory = () => !!answers.find(a => !!a[2]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ role: "assistant", content: a[1].answer }));
            const newHistory = [...history, { role: "user", content: question }];
        
            if (selectedFile && !getIsFileInHistory()) {
                const selectedFileAsb64 = await getB64File(selectedFile);
                newHistory[newHistory.length - 1] = { ...newHistory[newHistory.length - 1], file: selectedFileAsb64 };
            }

            const request: ChatRequest = {
                history: newHistory,
                approach: selectedFile ? Approaches.FileReadRetrieveRead : Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    temperature: 0.5,
                    index: indexName,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setSelectedFile(undefined);
    };

    const onFileSelected = (file: File) => {
        clearChat();
        setSelectedFile(file);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined;
        if (!hasAccess()) {
            timer = setTimeout(() => {
                window.location.replace(`${VITE_ONBOARDING_BASE_URL}?component=${window.location.href}`);
            }, 2000);
        }

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [profileQuery.data, profileQuery.error]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "10"));
    };

    const onIndexNameChange = (_ev?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        console.log("Index change", item);
        setIndexName(String(item?.key));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
        setSelectedAnswer(index);
    };

    const hasAccess = () => {
        return !profileQuery.isError && profileQuery.data && (profileQuery.data.user_role == 1 || profileQuery.data.user_role == 2);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    if (profileQuery.isLoading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (hasAccess() && !hasIndexes(indexList)) {
        return <Navigate to={Paths.knowledgeConnect} />;
    }

    return hasAccess() ? (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                {/* TODO: will be deprecated */}
                <div className={styles.commandUploadFile}>
                    <FileUpload onFileSelected={onFileSelected} selectedFile={selectedFile} />
                </div>
                <RefreshExamplesButton
                    className={styles.commandButton}
                    onClick={promptsQuery.refetch}
                    disabled={promptsQuery.isLoading || !!lastQuestionRef.current}
                />
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <h1 className={styles.chatEmptyStateTitle}>Ask Bulbshare AI</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2>
                            {promptsQuery.isFetching ? (
                                <ExampleLoadingList />
                            ) : (
                                <ExampleList examples={promptsQuery.error ? EXAMPLES : promptsQuery.data} onExampleClicked={onExampleClicked} />
                            )}
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <UploadedFileContext.Provider value={{ file: selectedFile, setFile: setSelectedFile }}>
                            <QuestionInput
                                clearOnSend
                                placeholder="What do you want to know? (ie. What are the latest trends about sustainability?)"
                                disabled={isLoading}
                                onSend={question => makeApiRequest(question)}
                            />
                        </UploadedFileContext.Provider>
                    </div>
                </div>
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}
                <Panel
                    headerText="Settings"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    {profileQuery.data?.display_name}
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />
                    <Dropdown
                        label="Search index"
                        selectedKey={indexName}
                        disabled={!indexList || indexList.length < 2}
                        onChange={onIndexNameChange}
                        placeholder="Select an option"
                        options={indexList}
                        styles={dropdownStyles}
                    />
                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.loginLoadingRoot}>
                <div className={styles.loginLoadingContainer}>
                    {profileQuery.isLoading ? (
                        <>Checking user authentication ...</>
                    ) : profileQuery.isError ? (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>{profileQuery.error.toString()}</p>
                            <p>Redirecting to the login page ...</p>
                        </>
                    ) : (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>Redirecting to the login page ...</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chat;
