import { useState } from "react";
import { UserOrganisation } from "../../../api/profile";
import { GenericAutocomplete } from "./SegmentConfigurators";
import { OrganisationChannel } from "../../../api/getOrganisationChannels";

export type SyntheticSegmentState = ReturnType<typeof useSyntheticSegments>["state"];

interface Args {
    age?: [number, number];
    country?: GenericAutocomplete;
    gender?: GenericAutocomplete;
}

export const useSyntheticSegments = (args?: Args) => {
    const useSelectedOrganisation = useState<UserOrganisation>();
    const useSelectedChannel = useState<OrganisationChannel>();
    const useGender = useState<GenericAutocomplete | undefined>(args?.gender);
    const useCountry = useState<GenericAutocomplete | undefined>(args?.country);
    const useAge = useState<[number, number]>(args?.age ?? [16, 90]);

    return {
        state: {
            useSelectedOrganisation,
            useSelectedChannel,
            useGender,
            useCountry,
            useAge
        }
    };
};
