import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "../../../utils/reactQuerySetup";
import getFoldersContent from "../../foldersContent";

export const getFoldersContentQueryKey = (folderId: string) => [QueryKeys.oneDriveFoldersContent, { folderId }];

interface Args {
    folderId: string;
}

export const useFoldersContentQuery = (args: Args) =>
    useQuery({
        queryKey: getFoldersContentQueryKey(args.folderId),
        queryFn: () => getFoldersContent({ folderId: args.folderId })
    });
