import { styled } from "@mui/system";

import { PaddedPaper } from "../styled";

export const Wrapper = styled(PaddedPaper)`
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 0px;
    }

    .segmentSummary {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        margin: 0px 0px 16px;

        .summaryHeader {
            margin: 0px 0px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            h3 {
                margin: 0px;
            }

            .channelSummary {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
            }
        }
    }

    .translucent {
        opacity: 0.6;
    }
`;
