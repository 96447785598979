import { VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE } from "../utils/envVariables";
import { getHeaders } from "@shared/components/api/utils";

export interface UserOrganisation {
    organisationid: number;
    name: string;
    kb_indexes: [];
}

interface UserProfile {
    userref: string;
    display_name: string;
    user_role: 1;
    organisations: UserOrganisation[];
}

export default async function getProfile() {
    const response = await fetch(`${VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE}/api/kb_profile`, {
        method: "POST",
        headers: getHeaders(),
        credentials: "include",
        body: JSON.stringify({
            args: {},
            app_id: "kb"
        })
    });
    return (await response.json()).data;
}
