import { useState } from "react";
import { styled } from "@mui/material/styles";
import { SyntheticConversation } from "../../../pages/syntheticResponses/useConversation";
import { Button } from "@shared/components/ui/Button";
import { CircularProgress, DialogActions, DialogContent } from "@mui/material";
import getGeneratedPersona from "../../../api/getGeneratedPersona";
import { PersonaResponse } from "../../../types";
import SegmentConfiguratorsWithWrapper, {
    GenericAutocomplete,
    autocompleteCountries,
    autocompleteGenders
} from "../SegmentConfiguratorDialog/SegmentConfigurators";
import { useSyntheticSegments } from "../SegmentConfiguratorDialog/useSyntheticSegments";

const Wrapper = styled("div")`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;
`;

type PersonaSetupFormProps = {
    persona?: SyntheticConversation.User;
    onLoadedPersona: (persona: PersonaResponse) => void;
};
export default function PersonaSetupForm({ persona, onLoadedPersona }: PersonaSetupFormProps) {
    const personaCountry: GenericAutocomplete | undefined = autocompleteCountries.find(({ label }) => label === persona?.country);
    const personaGender: GenericAutocomplete | undefined = autocompleteGenders.find(({ value }) => value === persona?.gender);
    const { state: segmentState } = useSyntheticSegments({
        ...(persona?.age !== undefined && { age: [persona.age, persona.age + 1] }),
        country: personaCountry,
        gender: personaGender
    });

    const [isCreatingPersona, setIsCreatingPersona] = useState<boolean>();

    const generatePersona = async () => {
        if (!persona) {
            return;
        }

        try {
            setIsCreatingPersona(true);
            const generatedPersona = await getGeneratedPersona({
                age: segmentState.useAge[0],
                country: segmentState.useCountry[0]?.value || "n/a",
                gender: segmentState.useGender[0]?.value || "n/a",
                user_ref: persona.userRef
            });
            onLoadedPersona(generatedPersona);
        } catch (e) {
            console.error(e);
        } finally {
            setIsCreatingPersona(false);
        }
    };

    return (
        <>
            <DialogContent>
                <Wrapper>
                    <SegmentConfiguratorsWithWrapper {...segmentState} />
                </Wrapper>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", width: "100%" }}>
                <div className={"buttonLoaderWrapper"}>
                    <Button disabled={isCreatingPersona} onClick={generatePersona} width="auto" sx={{ padding: "0 48px" }}>
                        Load persona
                    </Button>
                    {isCreatingPersona && (
                        <div className="buttonLoader">
                            <CircularProgress size={24} />
                        </div>
                    )}
                </div>
            </DialogActions>
        </>
    );
}
