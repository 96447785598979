import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

import styles from "./style.module.scss";
import { Dropzone } from "@shared/components/ui/Dropzone";
import UploadPlaceholder from "./UploadPlaceholder";
import { UploadedFileContext } from "../../../../context/uploadedFileContext";

interface Props {
    isOpen: boolean;
    onClose(): void;
    onConfirm(file: File): void;
}

const MAX_FILE_SIZE = 4194000;

const FileUploadModal = (props: Props) => {
    const [file, setFile] = useState<File>();

    const onConfirm = () => {
        if (!file) {
            return;
        }
        props.onConfirm(file);
        props.onClose();
    };

    const onFileSelected = (files: File[]) => {
        if (!files.length) {
            return;
        }
        const file = files[0];
        if (file.size > MAX_FILE_SIZE) {
            console.error("File too big");
            return;
        }

        setFile(file);
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} fullWidth>
            <DialogTitle>Upload file</DialogTitle>

            <DialogContent>
                <Dropzone wrapperClassName={styles.dropzoneWrapper} onChange={onFileSelected}>
                    {file ? <div>Selected file: {file.name}</div> : <UploadPlaceholder />}
                </Dropzone>
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button disabled={!file} onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const FileUploadModalWrapper = (props: Omit<Props, "onConfirm">) => (
    <UploadedFileContext.Consumer>{context => <FileUploadModal onConfirm={context.setFile} {...props} />}</UploadedFileContext.Consumer>
);

export default FileUploadModalWrapper;
