import MicrosoftLogoIcon from "../../../assets/microsoft-logo.svg?react";
import UploadIcon from "../../../assets/upload-icon.svg?react";
import GoogleLogoIcon from "../../../assets/google-logo.svg?react";
import DropboxLogoIcon from "../../../assets/dropbox-logo.svg?react";

export enum ImportButtonsEnum {
    microsoft = 1,
    upload,
    google,
    dropbox
}

interface ButtonsData {
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    name: string;
    id: ImportButtonsEnum;
    isDisabled?: boolean;
}

export const importButtonsData: ButtonsData[] = [
    {
        Icon: MicrosoftLogoIcon,
        name: "Connect OneDrive",
        id: ImportButtonsEnum.microsoft
    },
    {
        Icon: UploadIcon,
        name: "Upload knowledge",
        id: ImportButtonsEnum.upload,
        isDisabled: true
    },
    {
        Icon: GoogleLogoIcon,
        name: "Connect Google Drive",
        id: ImportButtonsEnum.google,
        isDisabled: true
    },
    {
        Icon: DropboxLogoIcon,
        name: "Connect Dropbox",
        id: ImportButtonsEnum.dropbox,
        isDisabled: true
    }
];
