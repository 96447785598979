/// <reference types="vite-plugin-svgr/client" />

import { useRef, useState, useEffect, useMemo } from "react";
import { Navigate } from "react-router";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";

import styles from "./SyntheticResponses.module.scss";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import Loader from "../../components/Loader";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { getB64File } from "../../components/FileUpload";
import { useUserQuery } from "../../api/hooks/queries/useUserQuery";
import { UploadedFileContext } from "../../context/uploadedFileContext";
import { Paths } from "../../utils/routesEnum";
import { VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE } from "../../utils/envVariables";
import classNames from "classnames";
import UserResponsesTable from "../../components/SyntheticResponses/UserResponsesTable";
import SegmentConfiguratorDialog from "../../components/SyntheticResponses/SegmentConfiguratorDialog";
import { useSyntheticSegments } from "../../components/SyntheticResponses/SegmentConfiguratorDialog/useSyntheticSegments";
import { useConversation } from "./useConversation";
import UserQuerySummary from "../../components/SyntheticResponses/UserQuerySummary";
import ResponseSummary from "../../components/SyntheticResponses/ResponseSummary";
import SegmentConfigurators from "../../components/SyntheticResponses/SegmentConfiguratorDialog/SegmentConfigurators";
import { PaddedPaper } from "../../components/SyntheticResponses/styled";
import { Skeleton } from "@mui/material";
import { SegmentWrapper } from "../../components/SyntheticResponses/SegmentConfiguratorDialog/styles";

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 292 } };

// TODO: stop answer fetching button
const SyntheticResponses = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [indexName, setIndexName] = useState<string>("gptkbindex");
    const [retrieveCount, setRetrieveCount] = useState<number>(10);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [conversationHistory, setConversationHistory] = useState();

    const { state: segmentState } = useSyntheticSegments();
    const conversation = useConversation();

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, file?: string][]>([]);

    const profileQuery = useUserQuery();

    const indexList = useMemo(() => {
        const { data } = profileQuery;
        if (!data?.organisations.length) {
            return [];
        }

        const indexList = data.organisations.reduce<{ key: string; text: string }[]>((acc, org) => {
            if (org.kb_indexes && org.kb_indexes.length > 0) {
                org.kb_indexes.forEach((idx: any) => {
                    acc.push({
                        key: idx.value,
                        text: idx.name
                    });
                });
            }

            return acc;
        }, []);

        if (indexList.length > 0) {
            setIndexName(String(indexList[0].key));
        }
        return indexList;
    }, [profileQuery.data]);

    const makeApiRequest = async (question: string) => {
        conversation.startConversation(segmentState, question);
    };

    const continueConversation = async (question: string) => {
        conversation.continueConversation(question);
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setSelectedFile(undefined);
    };

    const onFileSelected = (file: File) => {
        clearChat();
        setSelectedFile(file);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [conversation.isLoading]);
    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined;
        if (!hasAccess()) {
            timer = setTimeout(() => {
                window.location.replace(`${VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE}/2/?redirect=${window.location.host}`);
            }, 2000);
        }

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [profileQuery.data, profileQuery.error]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "10"));
    };

    const onIndexNameChange = (_ev?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        console.log("Index change", item);
        setIndexName(String(item?.key));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
        setSelectedAnswer(index);
    };

    const hasAccess = () => {
        return !profileQuery.isError && profileQuery.data && (profileQuery.data.user_role == 1 || profileQuery.data.user_role == 2);
    };
    const hasIndexes = () => {
        return indexList && indexList.length > 0;
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    if (profileQuery.isLoading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (!hasIndexes()) {
        return <Navigate to={Paths.knowledgeConnect} />;
    }

    return hasAccess() ? (
        <div className={styles.container}>
            <div className={classNames(styles.commandsContainer, styles.outer)}>
                <SegmentConfiguratorDialog segmentState={segmentState} />
                <div className={styles.commandsContainer}>
                    <SettingsButton onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                </div>
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    <div className={styles.chatMessageStream} style={{ marginBottom: "24px" }}>
                        <SegmentWrapper>
                            <SegmentConfigurators useGender={segmentState.useGender} useCountry={segmentState.useCountry} useAge={segmentState.useAge} />
                        </SegmentWrapper>
                    </div>
                    {/* <UserResponsesTable responses={syntheticUserResponses} /> */}
                    {conversation.showAnswer ? (
                        <div className={classNames(styles.chatMessageStream, styles.grow)}>
                            {/* {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}

                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} /> */}

                            <UserQuerySummary query={conversation.currentQuery} />
                            {(conversation.isLoading || (conversation.users && conversation.users.length > 0)) && (
                                <ResponseSummary summary={conversation.summary} />
                            )}
                            {conversation.conversation.length > 0 &&
                                conversation.conversation.map((q, i) => (
                                    <PaddedPaper key={i}>
                                        <h4 style={{ marginTop: 0, marginBottom: "12px" }}>{q.question}</h4>
                                        {q.answer ? <div>{q.answer}</div> : <Skeleton variant="text" />}
                                    </PaddedPaper>
                                ))}
                            <QuestionInput
                                clearOnSend
                                placeholder="Is there anything more you'd like to know?"
                                disabled={conversation.isLoading}
                                shouldRenderSkeleton={conversation.isLoading}
                                onSend={continueConversation}
                            />
                            <UserResponsesTable users={conversation.users} responses={conversation.userResponses} />
                        </div>
                    ) : (
                        <div className={styles.chatEmptyState}>
                            <h1 className={styles.chatEmptyStateTitle}>Bulbshare synthetic response generator</h1>
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <UploadedFileContext.Provider value={{ file: selectedFile, setFile: setSelectedFile }}>
                            <QuestionInput
                                clearOnSend
                                placeholder="What do you want to know? (ie. What are the latest trends about sustainability?)"
                                disabled={conversation.isLoading}
                                onSend={question => makeApiRequest(question)}
                            />
                        </UploadedFileContext.Provider>
                    </div>
                </div>
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}
                <Panel
                    headerText="Settings"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    {profileQuery.data?.display_name}
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />
                    <Dropdown
                        label="Search index"
                        selectedKey={indexName}
                        disabled={!indexList || indexList.length < 2}
                        onChange={onIndexNameChange}
                        placeholder="Select an option"
                        options={indexList}
                        styles={dropdownStyles}
                    />
                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.loginLoadingRoot}>
                <div className={styles.loginLoadingContainer}>
                    {profileQuery.isLoading ? (
                        <>Checking user authentication ...</>
                    ) : profileQuery.isError ? (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>{profileQuery.error.toString()}</p>
                            <p>Redirecting to the login page ...</p>
                        </>
                    ) : (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>Redirecting to the login page ...</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SyntheticResponses;
