import { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
    title: string;
    children: ReactNode;
    onChange?: (expnaded: boolean) => void;
};

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
    "&.MuiPaper-root": {
        background: "white",
        borderRadius: "1rem",
        border: 0
    }
}));

const Expander = (props: Props) => {
    return (
        <StyledAccordion onChange={(e, expanded) => (props.onChange ? props.onChange(expanded) : null)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{props.title}</AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </StyledAccordion>
    );
};

export default Expander;
