import { useEffect } from "react";
import { ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "@shared/components/ui/Button";

import styles from "./oneDriveFolderExplorer.module.scss";

import FolderExplorer, { ROOT_FOLDER } from "./FolderExplorer";
import OrganisationSelector from "./OrganisationSelector";
import { useFolderSelect } from "./useFolderSelect";
import Toast from "../common/Toast";
import classNames from "classnames";
import { BlackWhiteButton } from "../Dialog/DialogButtons";

interface Props {
    isOpen: boolean;
    onToggleClose(): void;
    onChangeAccount(): void;
}

const OneDriveFolderExplorer = (props: Props) => {
    const {
        useSelectedFolderId: [selectedFolderId, setSelectedFolderId],
        useSelectedOrganisation: [selectedOrganisation, setSelectedOrganisation],
        isPending,
        errors,
        onCreate,
        onReset
    } = useFolderSelect();

    useEffect(() => {
        if (!props.isOpen) {
            setSelectedFolderId(undefined);
        }
    }, [props.isOpen]);

    const onFolderSelect = (id?: string) => {
        setSelectedFolderId(p => (p === id ? undefined : id));
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onToggleClose} fullWidth maxWidth="md">
            <DialogTitle>Select the folder to index</DialogTitle>
            <DialogContent>
                <OrganisationSelector selectedOrganisation={selectedOrganisation} setSelectedOrganisation={setSelectedOrganisation} />
                <p>Select which folder will be used for your index. All files within the folder will be uploaded to our servers.</p>
                <ButtonBase className={styles.blueBtn} onClick={props.onChangeAccount}>
                    Use another account
                </ButtonBase>
                <FolderExplorer selectedFolderId={selectedFolderId} onFolderSelect={onFolderSelect} folderId={ROOT_FOLDER} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <div>
                    <BlackWhiteButton variant="outlined" onClick={props.onToggleClose} className={classNames(styles.unifiedButtonWidth)}>
                        Cancel
                    </BlackWhiteButton>
                </div>
                <div className={"buttonLoaderWrapper"}>
                    <Button disabled={!selectedFolderId || isPending} onClick={onCreate} className={classNames(styles.unifiedButtonWidth)}>
                        Continue
                    </Button>
                    {isPending && (
                        <div className="buttonLoader">
                            <CircularProgress size={24} />
                        </div>
                    )}
                </div>
            </DialogActions>
            {errors && <Toast open type="error" onClose={onReset} message={errors.createOrganisationIndex || errors.folderSelectMutation} />}
        </Dialog>
    );
};

export default OneDriveFolderExplorer;
