import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { SyntheticSegmentState } from "../components/SyntheticResponses/SegmentConfiguratorDialog/useSyntheticSegments";
import { SyntheticConversation } from "../pages/syntheticResponses/useConversation";
import { VITE_API_BASE_URL } from "../utils/envVariables";

dayjs.extend(relativeTime);

const getAge = (birthday: string) => dayjs(new Date()).diff(birthday, "years");
const createUserFromData = ([birthday, avatar, country, displayName, gender, userId, userRef]: any[]) => ({
    age: getAge(birthday),
    avatar,
    country,
    displayName,
    gender,
    userId: parseInt(userId),
    userRef
});

// LEGO users
const usersData = [
    [
        "01/01/1982",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-92FE90BD-87D3-4A6C-AAB0-E56901214D5B-avatar-65371ac99c061.png",
        "United Kingdom",
        "James",
        "male",
        "117919",
        "user-92FE90BD-87D3-4A6C-AAB0-E56901214D5B"
    ],
    ["3/23/1984", "~/171715_avatar_634e68fe05dfe.jpg", "United Kingdom", "Gilly P", "female", "171715", "user-71194D3D-98CD-4C6B-A550-C56AE87380AF"],
    ["03/26/1976", "~/221213_avatar_634c497dc69df.jpg", "United Kingdom", "Caza555@bulbshare", "female", "221213", "user-42B6A114-C615-40B6-B0DB-2A8E2FA606C0"],
    [
        "03/08/1984",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-4E16481D-7E95-4685-BE07-5A9C2FDAA66E-avatar-6534c60e6d810.png",
        "United Kingdom",
        "emmalouisebrwn",
        "female",
        "220965",
        "user-4E16481D-7E95-4685-BE07-5A9C2FDAA66E"
    ],
    ["01/11/1971", "~/297970_avatar_64ac304c57878.jpg", "United Kingdom", "Blessed_one", "male", "297970", "user-28F6AED1-FAE3-46BC-8A6C-D5DA5825A619"],
    ["03/30/1994", "~/64859_avatar_5eb44ed5af441.png", "United Kingdom", "Motherthingcoming", "female", "64859", "user-0CCB55C9-F1B7-42E4-A65A-92FF38CEB6B4"],
    [
        "10/22/1995",
        "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=6941613099258579&height=200&width=200&ext=1709377023&hash=AfrJfBgzmDY93uv-jxCIcF1OsWn1vEqSCjCIyGhLHL0Qpg",
        "United Kingdom",
        "Humera M",
        "female",
        "207026",
        "user-3E784C22-7036-47AB-948C-F6DBB768358D"
    ],
    [
        "01/25/1985",
        "~/108308_avatar_652e4e2c942e4.jpg",
        "United Kingdom",
        "Zain Shakeel - Bulb Share Member",
        "male",
        "108308",
        "user-AE00C7D8-AB11-4013-B741-6E40A4FABC24"
    ],
    [
        "03/01/1979",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-42E35A00-4223-40D0-8D3D-33E2078EB3DE-avatar-65387ba0d243a.png",
        "United Kingdom",
        "Jo P",
        "female",
        "68214",
        "user-42E35A00-4223-40D0-8D3D-33E2078EB3DE"
    ],
    ["12/02/1972", "~/244447_avatar_634a97ae58b35.png", "United Kingdom", "Julie I", "female", "244447", "user-BD79B3AB-EBE5-4052-AF62-BE532260FDC8"],
    [
        "03/26/1991",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-0A364EEE-EBFD-49A0-B2C7-C889EDB50BC8-avatar-653861a2c3913.png",
        "United Kingdom",
        "Justine ",
        "female",
        "75128",
        "user-0A364EEE-EBFD-49A0-B2C7-C889EDB50BC8"
    ],
    ["08/17/1983", "~/201497_avatar_654800f2f2b99.png", "United Kingdom", "Katie Austin", "female", "201497", "user-3E039183-7745-43CF-B3D3-751526E6E593"],
    ["10/03/1997", "~/286280_avatar_634e78185af45.png", "United Kingdom", "Kelly ", "female", "286280", "user-5F5B7124-0B34-44DC-8447-599988E4943A"],
    [
        "11/04/1984",
        "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10226256674825974&height=200&width=200&ext=1676226297&hash=AeRk9SVtAL7xQ9_nEFI",
        "United Kingdom",
        "Money Savvy Mum",
        "female",
        "145526",
        "user-3862C84B-5D22-45B2-AB2C-C36B8026116E"
    ],
    ["01/01/1982", "~/19288_avatar_6349a0f550a5f.jpg", "United Kingdom", "Liz R", "female", "19288", "user-F00DB236-615A-41CF-A6B0-74C71AA9DF6B"],
    ["01/29/1993", "~/248823_avatar_6349b1590bd05.png", "United Kingdom", "Loukas K", "male", "248823", "user-CEB66094-E03D-4169-BBCC-47A0300A76E2"],
    ["1/1/1981", "~/248557_avatar_634d638d21a8a.png", "United Kingdom", "Lucy Bee", "female", "248557", "user-4B0E7492-C23D-4C01-8500-5B7201FD8313"],
    ["05/15/1985", "~/26821_avatar_5c5854892d794.jpg", "United Kingdom", "Tessa C", "female", "26821", "user-9F7C4FD9-DBBB-459F-BD21-4DB80348A4E8"],
    [
        "01/01/1970",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-AE2C8B8C-C4F4-4F77-B5CF-99D2439BBDEA-avatar-653997ef8b672.png",
        "United Kingdom",
        "Barbara  P",
        "female",
        "18333",
        "user-AE2C8B8C-C4F4-4F77-B5CF-99D2439BBDEA"
    ],
    ["11/08/1976", "~/31389_avatar_655277c8c626e.jpg", "United Kingdom", "Shell R", "female", "31389", "user-C5832FE5-81C5-4CD8-ABD2-F1B0FB988696"],
    ["01/01/1971", "~/108282_avatar_5f88caa1c5e79.jpg", "United Kingdom", "Seanski", "male", "108282", "user-7B9A9D73-EC46-4D38-BB1E-BC80B9D5CD8F"],
    [
        "1/1/1981",
        "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-65FEE6B2-AA9B-4F04-90CE-BC097FECBDCB-avatar-6534f9a9826b0.png",
        "United Kingdom",
        "WaffStar",
        "male",
        "206655",
        "user-65FEE6B2-AA9B-4F04-90CE-BC097FECBDCB"
    ],
    [
        "06/18/1992",
        "~/18181_avatar_62d96d478f5ae.png",
        "United Kingdom",
        "Nicolle - thefreestylingmummy",
        "female",
        "18181",
        "user-9BFE75B0-637B-40AC-AB62-30C93B99BFDC"
    ],
    [
        "09/05/1991",
        "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10160692325585540&height=200&width=200&ext=1682415263&hash=AeRenlxndj6dJFIwEXQ",
        "United Kingdom",
        "Yasmin S",
        "female",
        "205294",
        "user-D344663C-4526-4A45-9A66-86D6694B8448"
    ]
];

const hardcodedUsers: SyntheticConversation.User[] = [
    {
        age: 50,
        avatar: "~/248377_avatar_6398f6978f1ee.png",
        country: "United Kingdom",
        displayName: "Pip Pip",
        gender: "male",
        userId: 248377,
        userRef: "user-A4095650-7F17-4C21-A784-CC49D4177CD8"
    },
    {
        age: 31,
        avatar: "~/18181_avatar_62d96d478f5ae.png",
        country: "United Kingdom",
        displayName: "Nicolle - thefreestylingmummy",
        gender: "female",
        userId: 18181,
        userRef: "user-9BFE75B0-637B-40AC-AB62-30C93B99BFDC"
    },
    {
        age: 39,
        avatar: "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-4E16481D-7E95-4685-BE07-5A9C2FDAA66E-avatar-6534c60e6d810.png",
        country: "United Kingdom",
        displayName: "emmalouisebrwn",
        gender: "female",
        userId: 220965,
        userRef: "user-4E16481D-7E95-4685-BE07-5A9C2FDAA66E"
    },
    {
        age: 45,
        avatar: "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-15924447-60B4-4CAE-8B0E-C3A55E523EF2-avatar-6531df18bf0e5.png",
        country: "United Kingdom",
        displayName: "damienbroom78",
        gender: "male",
        userId: 249051,
        userRef: "user-15924447-60B4-4CAE-8B0E-C3A55E523EF2"
    },
    {
        age: 53,
        avatar: "~/108282_avatar_5f88caa1c5e79.jpg",
        country: "United Kingdom",
        displayName: "Seanski",
        gender: "male",
        userId: 108282,
        userRef: "user-7B9A9D73-EC46-4D38-BB1E-BC80B9D5CD8F"
    },
    {
        age: 41,
        avatar: "https://dev-bshr-media.s3.eu-west-1.amazonaws.com/data/users/user-6156F0AB-E420-422A-B5FF-9A46147C6D46-avatar-653581e8be738.png",
        country: "United Kingdom",
        displayName: "Pooksie@Bulbshare",
        gender: "female",
        userId: 172307,
        userRef: "user-6156F0AB-E420-422A-B5FF-9A46147C6D46"
    }
];

export const getUserList = async (segments: SyntheticSegmentState): Promise<SyntheticConversation.User[]> => {
    await new Promise(r => setTimeout(r, 500 * Math.random() + 300));
    // return hardcodedUsers; // nyx users
    return usersData.slice(0, 5).map(createUserFromData); // LEGO users
    // const [selectedChannel] = segments.useSelectedChannel;
    // if (!selectedChannel) {
    //     throw new Error("No selected channel");
    // }
    // const url = `${VITE_API_BASE_URL}/synthetic_responses/communities/${selectedChannel.channelId}`;

    // const result = await axios.get<any, { data: SyntheticConversation.User[] }>(url, {
    //     headers: { "Content-Type": "application/json" },
    //     withCredentials: true
    // });

    // return result.data;
};

export const getUserResponse = async (args: {
    segment?: SyntheticSegmentState;
    user: SyntheticConversation.User;
    question: string;
}): Promise<Required<SyntheticConversation.UserResponse>> => {
    const url = `${VITE_API_BASE_URL}/synthetic_data/generate_response`;
    const dto = JSON.stringify({
        ...args.user,
        query: args.question
    });

    const response = await axios.post<any, { data: Required<SyntheticConversation.UserResponse> }>(url, dto, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};

export const getResponsesSummary = async (args: { userResponses: SyntheticConversation.User[]; question: string }) => {
    const url = `${VITE_API_BASE_URL}/synthetic_data/generate_summary`;
    const dto = JSON.stringify({
        question: args.question,
        responses: args.userResponses
    });

    const response = await axios.post<any, { data: Required<SyntheticConversation.ResponseSummary> }>(url, dto, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};

export const getConversation = async (args: { userResponses: SyntheticConversation.User[]; question: string; newQuestion: string }) => {
    const url = `${VITE_API_BASE_URL}/synthetic_data/generate_conversation`;
    const dto = JSON.stringify({
        question: args.question,
        responses: args.userResponses,
        newQuestion: args.newQuestion
    });

    const response = await axios.post<any, { data: Required<SyntheticConversation.ResponseConversation> }>(url, dto, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
    });

    return response.data;
};
