import { useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";

import styles from "./ImportButtonsStyles.module.scss";

import { ImportButtonsEnum, importButtonsData } from "./importButtonsData";
import ConnectButton from "../../ConnectButton";
import { useMsAuth } from "./useMsAuth";
import Toast from "../../common/Toast";
import { hasIndexes, useGetIndexList } from "../../../utils/indicesHelpers";

interface Props {
    onFolderExplorerOpen(): void;
    msAuth: ReturnType<typeof useMsAuth>;
}

export default function ImportButtons(props: Props) {
    const [isLoadingEnum, setIsLoadingEnum] = useState<ImportButtonsEnum>();
    const [error, setError] = useState<Error>();

    const indexList = useGetIndexList();

    const handleError = (error: unknown) => {
        console.error(error);
        if (error instanceof Error) {
            setError(error);
        }
    };
    const onMsLogin = async () => {
        try {
            setError(undefined);
            setIsLoadingEnum(ImportButtonsEnum.microsoft);
            await props.msAuth.logIn();
            props.onFolderExplorerOpen();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoadingEnum(undefined);
        }
    };

    const onMsExplorerOpen = async () => {
        try {
            setError(undefined);
            setIsLoadingEnum(ImportButtonsEnum.microsoft);
            await props.msAuth.getToken();
            props.onFolderExplorerOpen();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoadingEnum(undefined);
        }
    };

    const onMicrosoftClick = async () => {
        // check if user has MS token with the account
        if (!props.msAuth.isAuthenticated) {
            onMsLogin();
        } else {
            // open folder explorer
            onMsExplorerOpen();
        }
    };

    const clickHandlers: Record<ImportButtonsEnum, (e: React.MouseEvent<HTMLButtonElement>) => void> = {
        [ImportButtonsEnum.microsoft]: onMicrosoftClick,
        [ImportButtonsEnum.upload]: () => console.log("upload"),
        [ImportButtonsEnum.google]: () => console.log("google"),
        [ImportButtonsEnum.dropbox]: () => console.log("dropbox")
    };

    const getIsDisabled = (id: ImportButtonsEnum) => {
        if (id === ImportButtonsEnum.microsoft) {
            return hasIndexes(indexList);
        }
        return false;
    };

    return (
        <>
            <div className={styles.wrapper}>
                {importButtonsData.map(bd => {
                    const isLoading = isLoadingEnum === bd.id;
                    const isDisabled = bd.isDisabled || isLoading || getIsDisabled(bd.id);

                    const Component = (
                        <ConnectButton key={bd.isDisabled ? undefined : bd.id} onClick={clickHandlers[bd.id]} disabled={isDisabled}>
                            <bd.Icon />
                            <span>{bd.name}</span>
                            {isLoading && (
                                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    <CircularProgress size={24} />
                                </div>
                            )}
                        </ConnectButton>
                    );

                    if (bd.isDisabled) {
                        return (
                            <Tooltip key={bd.id} title="Coming soon" enterDelay={500}>
                                <div>{Component}</div>
                            </Tooltip>
                        );
                    }

                    return Component;
                })}
            </div>
            {error && <Toast open type="error" onClose={() => setError(undefined)} message={error.message} />}
        </>
    );
}
