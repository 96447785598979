import { styled } from "@mui/material";

import UploadIcon from "../../../../assets/upload-icon.svg?react";

export const PlaceholderWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;

    .title {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
`;

const UploadPlaceholder = () => {
    return (
        <PlaceholderWrapper>
            <UploadIcon />
            <div className="title">Drag and drop here</div>
        </PlaceholderWrapper>
    );
};

export default UploadPlaceholder;
