import styles from "./GettingThingsReadyUI.module.scss";

import TadaIcon from "../../assets/tada-confetti.svg?react";

const GettingThingsReadyComponent = () => {
    return (
        <>
            <TadaIcon className={styles.successIcon} />
            <div className={styles.textWrapper}>
                <h1>We are getting things ready</h1>
                <div className={styles.subTitle}>We are syncing your files into your organisation index. This process can take up to 2h. Please come back</div>
            </div>
        </>
    );
};

export default GettingThingsReadyComponent;
