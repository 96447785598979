import { Alert, AlertColor, Snackbar } from "@mui/material";
import React from "react";

interface Props {
    type?: AlertColor;
    message?: string;
    open: boolean;
    duration?: number;
    onClose?: () => void;
}

export default function Toast({ type = "success", message, open, duration = 2000, onClose }: Props) {
    return (
        <Snackbar open={open} autoHideDuration={duration} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={onClose}>
            <Alert severity={type} icon={false}>
                {message}
            </Alert>
        </Snackbar>
    );
}
