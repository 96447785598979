import { InteractiveChatResponse } from "../types";
import { post } from "@shared/components/api/http";
import { VITE_API_BASE_URL } from "../utils/envVariables";

type InteractiveChatInputProps = {
    message: string;
};

export default function interactiveChat(props: InteractiveChatInputProps, personaId: string): Promise<InteractiveChatResponse> {
    return post<InteractiveChatInputProps, InteractiveChatResponse>(`${VITE_API_BASE_URL}/synthetic_data/chat/${personaId}`, props, {
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include"
    });
}
