import { VITE_RESOURCES_BASE_URL } from "./envVariables";

export enum IMAGE_TYPES {
    BRIEF_BANNER = "briefs_banner_image",
    COVER_PHOTO = "briefs_cover_photo",
    AD_IMAGE = "briefs_ad_image",
    POLL_ITEM = "pollitem_image",
    CHANNEL_LOGO = "brand_logo",
    USER_AVATAR = "user_avatar"
}

export enum VIDEO_TYPES {
    VIDEO_COVER = "briefs_cover_video_mobile"
}

export default (resource: string | undefined, imageType: IMAGE_TYPES | VIDEO_TYPES) => {
    if (resource) {
        return resource.startsWith("http") ? resource : `${VITE_RESOURCES_BASE_URL}${imageType}.html?name=${resource}`;
    }
    return "";
};
