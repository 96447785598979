/// <reference types="vite-plugin-svgr/client" />

import { useEffect, useState } from "react";
import { useUserQuery } from "../../api/hooks/queries/useUserQuery";
import { VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE } from "../../utils/envVariables";
import { Button } from "@shared/components/ui/Button";
import { SyntheticUser } from "../../types";
import VirtualPersona from "../../components/UserVirtualChat/VirtualPersona";
import VirtualChat from "../../components/UserVirtualChat/VirtualChat";
import Loader from "../../components/Loader";
import styles from "./SyntheticResponsesVirtualPersona.module.scss";

const SyntheticResponsesVirtualPersona = () => {
    const [persona, setPersona] = useState<SyntheticUser>();

    const profileQuery = useUserQuery();

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | undefined;
        if (!hasAccess()) {
            timer = setTimeout(() => {
                window.location.replace(`${VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE}/2/?redirect=${window.location.host}`);
            }, 2000);
        }

        return () => {
            if (timer !== undefined) {
                clearTimeout(timer);
            }
        };
    }, [profileQuery.data, profileQuery.error]);

    const hasAccess = () => {
        return !profileQuery.isError && profileQuery.data && (profileQuery.data.user_role == 1 || profileQuery.data.user_role == 2);
    };

    if (profileQuery.isLoading) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        );
    }

    return hasAccess() ? (
        <div className={styles.container}>
            <div className={styles.createPersona}>
                <h1>
                    {persona ? (
                        <>
                            Chat with {persona.userRef}
                            <Button onClick={() => setPersona(undefined)} variant="outlined">
                                End chat
                            </Button>
                        </>
                    ) : (
                        `Create virtual persona for chat`
                    )}
                </h1>
                <VirtualPersona persona={persona} onCreatePersona={persona => setPersona(persona)} reset={() => setPersona(undefined)} />
                {persona && <VirtualChat virtualPersona={persona} />}
            </div>
        </div>
    ) : (
        <div className={styles.container}>
            <div className={styles.loginLoadingRoot}>
                <div className={styles.loginLoadingContainer}>
                    {profileQuery.isLoading ? (
                        <>Checking user authentication ...</>
                    ) : profileQuery.isError ? (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>{profileQuery.error.toString()}</p>
                            <p>Redirecting to the login page ...</p>
                        </>
                    ) : (
                        <>
                            <h2>Unauthenticated</h2>
                            <p>Redirecting to the login page ...</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SyntheticResponsesVirtualPersona;
