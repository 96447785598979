import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const ChannelSelectorWrapper = styled("div")`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
`;
export const SegmentWrapper = styled(Paper)`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    padding: 16px;
`;
