import { useEffect, useState } from "react";

export const useIsScrollOnTop = () => {
    const [isOnTop, setIsOnTop] = useState(window.scrollY === 0);

    const handleScroll = () => {
        const isCurrentlyOnTop = window.scrollY === 0;

        setIsOnTop(isCurrentlyOnTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isOnTop]);

    return isOnTop;
};
