import { get } from "@shared/components/api/http";
import { getHeaders } from "@shared/components/api/utils";

export interface MicrosoftDriveItem {
    "@microsoft.graph.downloadUrl"?: string;
    id: string;
    name: string;
    file?: {
        mimeType: string;
    };
    folder?: {
        childCount: number;
    };
}

interface GetFolderContentDTO {
    files: MicrosoftDriveItem[];
}

interface Args {
    folderId: string;
}

export default async function getFoldersContent(args: Args): Promise<{ files: MicrosoftDriveItem[] } | undefined> {
    try {
        return await get<GetFolderContentDTO>(`${import.meta.env.VITE_API_BASE_NODE_URL}/folders/${args.folderId}/content`, {
            headers: getHeaders(),
            credentials: "include"
        });
    } catch (e) {
        console.error(e);
    }
}
