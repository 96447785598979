import { get } from "@shared/components/api/http";
import { getHeaders } from "@shared/components/api/utils";

export interface OrganisationChannel {
    channelId: number;
    channelRef: string;
    name: string;
    description: string;
    longDescription: string;
    logo: string;
    isPrivate: boolean;
    isRemoved: boolean;
    status: number;
    organisationId: number;
}

interface Args {
    organisationId: number;
}

export default async function getOrganisationChannels(args: Args) {
    return await get<OrganisationChannel[]>(`${import.meta.env.VITE_API_BASE_NODE_URL}/community/organisations/${args.organisationId}/channels`, {
        headers: getHeaders(),
        credentials: "include"
    });
}
