import styles from "./Example.module.css";
import { ExampleLoading } from "./ExampleLoading";

export const ExampleLoadingList = () => {
    return (
        <ul className={styles.examplesNavList}>
            {[...Array(3)].map((_, i) => (
                <li key={i}>
                    <ExampleLoading />
                </li>
            ))}
        </ul>
    );
};
