import { SyntheticUser } from "../../../../types";

export interface UserElementProps {
    user: SyntheticUser;
    responseKey?: keyof SyntheticUser;
}

const GenericUserData = (props: UserElementProps) => {
    if (!props.responseKey) {
        return null;
    }

    return <>{props.user[props.responseKey]}</>;
};

export default GenericUserData;
