import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Button } from "@shared/components/ui/Button";
import OrganisationsChannelSelector from "./OrganisationsChannelSelector";
import SegmentConfigurators from "./SegmentConfigurators";
import { SyntheticSegmentState } from "./useSyntheticSegments";
import { useUserQuery } from "../../../api/hooks/queries/useUserQuery";
import { useOrganisationChannelsQuery } from "../../../api/hooks/queries/useOrganisationChannelsQuery";

interface Props {
    segmentState: SyntheticSegmentState;
}

const SegmentConfiguratorDialog = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const { data: profileData } = useUserQuery();
    const { data: organisationChannelsData } = useOrganisationChannelsQuery({ organisationId: props.segmentState.useSelectedOrganisation[0]?.organisationid });

    useEffect(() => {
        if (profileData?.organisations?.length) {
            props.segmentState.useSelectedOrganisation[1](profileData.organisations[0]);
        }
    }, [profileData]);

    useEffect(() => {
        if (organisationChannelsData?.length) {
            props.segmentState.useSelectedChannel[1](organisationChannelsData[0]);
        } else {
            props.segmentState.useSelectedChannel[1](undefined);
        }
    }, [organisationChannelsData]);

    const { segmentState } = props;

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Button variant="outlined" width="auto" onClick={() => setIsOpen(true)}>
                Configure segment
            </Button>
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
                <DialogTitle>Configure segment</DialogTitle>
                <DialogContent>
                    <OrganisationsChannelSelector
                        useSelectedOrganisation={segmentState.useSelectedOrganisation}
                        useSelectedChannel={segmentState.useSelectedChannel}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: "flex-end", width: "100%" }}>
                    <Button width="112px" onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SegmentConfiguratorDialog;
