import { useMemo } from "react";
import { useUserQuery } from "../api/hooks/queries/useUserQuery";

interface Args {
    onIndexListChange?(newIndexName: string): void;
}

export const useGetIndexList = (args?: Args) => {
    const profileQuery = useUserQuery();

    return useMemo(() => {
        const { data } = profileQuery;
        if (!data?.organisations?.length) {
            return [];
        }

        const indexList = data.organisations.reduce((acc: { key: string; text: string }[], org) => {
            if (org.kb_indexes && org.kb_indexes.length > 0) {
                org.kb_indexes.forEach((idx: any) => {
                    acc.push({
                        key: idx.value,
                        text: idx.name
                    });
                });
            }

            return acc;
        }, []);

        if (indexList.length > 0 && args?.onIndexListChange) {
            args.onIndexListChange(String(indexList[0].key));
        }
        return indexList;
    }, [profileQuery.data]);
};

export const hasIndexes = (
    indexList?: {
        key: string;
        text: string;
    }[]
) => {
    return indexList && indexList.length > 0;
};
