import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { SyntheticUser } from "../../../types";

import styles from "./UsersTable.module.scss";

import PaperLoader from "../../SyntheticResponses/PaperLoader";
import GenericUserData, { UserElementProps } from "./UsersItems/Generic";
import DisplayNameData from "./UsersItems/DisplayName";
import EditUserDialog from "../EditUserDialog";
import DescriptionData from "./UsersItems/Description";

enum Columns {
    userName = "userName",
    age = "age",
    gender = "gender",
    country = "country",
    description = "description"
}

interface ColumnItem {
    id: Columns;
    name: string;
    userKey?: keyof SyntheticUser;
    width?: number;
}

const headerItems: ColumnItem[] = [
    { id: Columns.userName, name: "Display name", userKey: "displayName", width: 15 },
    { id: Columns.age, name: "Age", userKey: "age", width: 10 },
    { id: Columns.gender, name: "Gender", userKey: "gender", width: 10 },
    { id: Columns.country, name: "Country", userKey: "country", width: 20 },
    { id: Columns.description, name: "Description", userKey: "description", width: 40 }
];

const contentItems: Record<Columns, React.ComponentType<UserElementProps>> = {
    [Columns.userName]: DisplayNameData,
    [Columns.age]: GenericUserData,
    [Columns.gender]: GenericUserData,
    [Columns.country]: GenericUserData,
    [Columns.description]: DescriptionData
};

interface Props {
    users?: SyntheticUser[];
    onSelectedUser: (user: SyntheticUser) => void;
    onUpdatedUser: (user: SyntheticUser) => void;
}

const UsersTable = (props: Props) => {
    if (!props.users) {
        return <PaperLoader text="Loading users..." />;
    }

    return (
        <>
            {props.users.length ? (
                <TableContainer component={Paper} className={styles.wrapper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {headerItems.map(item => (
                                    <TableCell key={item.id}>{item.name}</TableCell>
                                ))}
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.users.map((user, index) => (
                                <TableRow
                                    key={user.userRef + index}
                                    onClick={() => (props.onSelectedUser ? props.onSelectedUser(user) : null)}
                                    className={user.disabled ? styles.disabledRow : styles.enabledRow}
                                >
                                    {headerItems.map(headerItem => {
                                        const ContentComponent = contentItems[headerItem.id];
                                        return (
                                            <TableCell key={headerItem.id} sx={{ verticalAlign: "baseline", width: headerItem.width + "%" }}>
                                                <ContentComponent key={headerItem.id} user={user} responseKey={headerItem.userKey} />
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell sx={{ width: "5%" }}>
                                        {!user.disabled && <EditUserDialog user={user} onConfirm={updatedUser => props.onUpdatedUser(updatedUser)} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Paper sx={{ padding: "16px" }}>No users imported</Paper>
            )}
        </>
    );
};

export default UsersTable;
