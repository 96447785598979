import PaperLoader from "../PaperLoader";
import { PaddedPaper } from "../styled";

interface Props {
    summary?: string;
}

const ResponseSummary = (props: Props) => {
    if (!props.summary) {
        return (
            <PaperLoader
                shouldHideCircularLoader
                text="We're generating data, the summary will appear when all responses are generated..."
                placeholderRows={4}
            />
        );
    }

    return <PaddedPaper>{props.summary}</PaddedPaper>;
};

export default ResponseSummary;
