import { Dialog, DialogTitle, IconButton } from "@mui/material";

import { StateHook } from "@shared/types";
import { SyntheticConversation } from "../../../pages/syntheticResponses/useConversation";
import { PersonaResponse } from "../../../types";
import PersonaSetupForm from "../PersonaSetupForm";
import CloseIcon from "@mui/icons-material/Close";
import InteractiveChat from "../InteractiveChat";
import { ItemComponent } from "@shared/types";
import { useEffect, useState } from "react";
import InteractiveChatDialog from "../InteractiveChat";
import { Button } from "@shared/components/ui/Button";

interface Props {
    useSelectedResponse: StateHook<SyntheticConversation.SelectedResponse | undefined>;
}

enum Screen {
    persona = "generated-persona",
    chat = "interactive-chat"
}

const UserImpersonatorDialog = (props: Props) => {
    const [selectedResponse, setSelectedResponse] = props.useSelectedResponse;
    const [currentScreen, setCurrentScreen] = useState<Screen>(Screen.chat);
    const [items, setItems] = useState<ItemComponent[] | []>([]);
    const [currentPersona, setCurrentPersona] = useState<PersonaResponse>();

    const onClose = () => {
        setSelectedResponse(undefined);
    };

    const resetState = () => {
        setCurrentScreen(Screen.chat);
        setCurrentPersona(undefined);
    };

    useEffect(() => {
        if (selectedResponse?.user) {
            setItems([
                {
                    id: "interactive-chat",
                    ItemComponent: InteractiveChat,
                    props: { cat: "cat" }
                },
                {
                    id: "generate-persona",
                    ItemComponent: PersonaSetupForm,
                    props: { persona: selectedResponse?.user }
                }
            ]);
        }
    }, [selectedResponse?.user]);

    return (
        // we might want to make this fullScreen instead
        <Dialog
            open={!!selectedResponse}
            onClose={onClose}
            fullScreen
            fullWidth
            maxWidth="lg"
            TransitionProps={{
                onExited: resetState
            }}
        >
            <DialogTitle>Synthetic persona {selectedResponse?.user.displayName}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    width="200px"
                    onClick={() => setCurrentScreen(currentScreen === Screen.persona ? Screen.chat : Screen.persona)}
                    disabled={!currentPersona}
                >
                    {currentScreen === Screen.persona ? "Configure persona" : "Chat"}
                </Button>
            </div>

            {currentScreen === Screen.chat || !currentPersona ? (
                <PersonaSetupForm persona={selectedResponse?.user} onLoadedPersona={persona => setCurrentPersona(persona)} />
            ) : (
                <InteractiveChatDialog persona={currentPersona} />
            )}

            {/*{items.length ? <VerticalGallery*/}
            {/*    onLoadedItem={(itemId: Screen) => setCurrentScreen(itemId)} allowScroll items={items} /> : <div />}*/}
        </Dialog>
    );
};

export default UserImpersonatorDialog;
