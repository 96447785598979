import { useEffect } from "react";
import { Autocomplete, Skeleton, TextField } from "@mui/material";

import styles from "./organisationSelector.module.scss";

import { useUserQuery } from "../../../api/hooks/queries/useUserQuery";
import { UserOrganisation } from "../../../api/profile";

interface Props {
    selectedOrganisation?: UserOrganisation;
    setSelectedOrganisation(id?: UserOrganisation): void;
}

const OrganisationSelector = (props: Props) => {
    const userQuery = useUserQuery();

    useEffect(() => {
        if (userQuery.data && userQuery.data.organisations.length === 1) {
            const organisation = userQuery.data.organisations.at(-1);
            if (organisation) {
                props.setSelectedOrganisation(organisation);
            }
        }
    }, [userQuery.data]);

    if (userQuery.isLoading) {
        return <Skeleton variant="text" />;
    }

    if (!Array.isArray(userQuery.data?.organisations)) {
        return <div>Your user account isn't associated with any organisations.</div>;
    }

    if (userQuery.data?.organisations.length === 1) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div>Select The organisation you're creating an index for.</div>
            <Autocomplete
                disablePortal
                value={props.selectedOrganisation ?? null}
                onChange={(e, v) => props.setSelectedOrganisation(v ?? undefined)}
                id="combo-box-demo"
                getOptionLabel={item => item.name}
                options={userQuery.data?.organisations || []}
                sx={{ width: 300 }}
                renderInput={params => <TextField {...params} label="Organisation" />}
            />
        </div>
    );
};

export default OrganisationSelector;
