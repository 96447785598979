import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { AccountInfo } from "@azure/msal-browser";

import { filesReadRequest } from "../../../utils/msAuthConfig";
import { useUpdateMSAccessToken } from "../../../api/hooks/mutations/useUpdateMSAccessToken";

export const useMsAuth = () => {
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const updateMSAccessTokenMutation = useUpdateMSAccessToken();

    const handleAcquireTokenPopup = async (account?: AccountInfo) => instance.acquireTokenPopup({ ...filesReadRequest, account });

    const handleAcquireTokenSilent = async (account?: AccountInfo) => {
        try {
            return await instance.acquireTokenSilent({ ...filesReadRequest, account });
        } catch (error) {
            console.error(error);
            if (error instanceof Error && error.name) {
                return handleAcquireTokenPopup(account);
            }
            throw error;
        }
    };

    const handleUpdateMSAccessToken = (accessToken: string) => updateMSAccessTokenMutation.mutateAsync({ accessToken });

    const logIn = async () => {
        const result = await instance.loginPopup(filesReadRequest);

        await handleUpdateMSAccessToken(result.accessToken);
    };

    const logOut = async () => {
        instance.logoutPopup({
            postLogoutRedirectUri: location.pathname,
            mainWindowRedirectUri: location.pathname
        });
    };

    const getAccounts = async () => {
        const accounts = await instance.getAllAccounts();
        return accounts;
    };

    const getToken = async () => {
        const accounts = await instance.getAllAccounts();
        if (accounts.length === 0) {
            // need to register
            return logIn();
        }
        if (accounts.length > 1) {
            // user might need to select which account
        }
        const tokenData = await handleAcquireTokenSilent(accounts.at(-1));
        if (!tokenData) {
            console.error("No token data received");
        }
        // send to BE
        await handleUpdateMSAccessToken(tokenData.accessToken);
    };

    return { isAuthenticated, logIn, logOut, getAccounts, getToken };
};
