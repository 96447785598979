import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import styles from "../../../pages/syntheticResponses/SyntheticResponses.module.scss";
import { QuestionInput } from "../../QuestionInput";
import { DialogContent, Skeleton } from "@mui/material";
import { DialogActions } from "@mui/material";
import interactiveChat from "../../../api/interactiveChat";
import { PersonaResponse } from "../../../types";

const Wrapper = styled("div")`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1rem;

    & .msg {
        border-radius: 1rem;
        margin-bottom: 1rem;
        padding: 0.438rem 0.938rem;
        width: 70%;
    }

    & .msg.sent {
        background: #42a5f5;
        color: #fff;
        align-self: flex-end;
    }

    & .msg.rcvd {
        border-radius: 1rem;
        background: #f1f1f1;
        color: #555;
        align-self: flex-start;
    }
`;

type Message = {
    type: string;
    message: string;
};

type InteractiveChatDialogProps = {
    persona: PersonaResponse;
};
export default function InteractiveChatDialog({ persona }: InteractiveChatDialogProps) {
    const [isSendingMessage, setSendingMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);

    useEffect(() => {
        if (persona) {
            setMessages([{ type: "rcvd", message: persona.generated_persona.description }]);
        }
    }, []);
    console.log(messages);
    const chatWithOpenAi = async (message: string) => {
        let chat = null;
        try {
            setSendingMessage(true);
            chat = await interactiveChat({ message }, persona.persona_id);
            setMessages([...messages, { type: "sent", message }, { type: "rcvd", message: chat?.response }]);
            setSendingMessage(false);
        } catch (e) {}
    };

    return (
        <>
            <DialogContent>
                <Wrapper>
                    {messages.map(({ message, type }, index) => (
                        <div key={index} className={`msg ${type}`}>
                            {message}
                        </div>
                    ))}
                    {isSendingMessage && <Skeletons />}
                </Wrapper>
            </DialogContent>
            <DialogActions>
                <div className={styles.chatInput}>
                    <QuestionInput
                        clearOnSend
                        placeholder="What do you want to know? (ie. What are the latest trends about sustainability?)"
                        disabled={isSendingMessage}
                        onSend={question => {
                            setMessages([...messages, { type: "sent", message: question }]);
                            chatWithOpenAi(question);
                        }}
                    />
                </div>
            </DialogActions>
        </>
    );
}

const Skeletons = () => {
    const data = useMemo(() => Array.from(new Array(3), (_, i) => ({ width: Math.random() * 60 + 40 })), []);

    return (
        <div className="msg rcvd">
            {data.map(({ width }, i) => (
                <Skeleton key={i} variant="text" width={`${width}%`} />
            ))}
        </div>
    );
};
