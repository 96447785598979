import { QueryClient } from "@tanstack/react-query";

export enum QueryKeys {
    user = 1,
    oneDriveFolders,
    oneDriveFoldersContent,
    organisationChannels,
    syntheticUserResponse,
    prompts
}

export enum MutationKeys {
    folderSelect = 1,
    organisationIndexCreate,
    updateMSAccessToken
}

export const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5 } } });
